import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { translateEnum } from "@/app/base/utils/i18n";
import { qualificationEventsPerStage } from "@/app/common/customer/qualificationEventsPerStage";
import { useCustomerStore } from "@/app/customer/store/CustomerStore";
import { salesEventsPerStage } from "@/app/opportunities/model/salesEventsPerStage";
import { useOpportunityStore } from "@/app/opportunities/store/OpportunityStore";
import { AfterSalesEvent } from "@/services/backend/models/AfterSalesEvent";
import { type Event } from "@/services/backend/models/Event";
import { EventType } from "@/services/backend/models/EventType";
import { QualificationEvent } from "@/services/backend/models/QualificationEvent";
import { EventControllerService } from "@/services/backend/services/EventControllerService";

interface EventFetchParams {
  projectId?: number;
  customerId?: number;
  opportunityId?: number;
  eventType: EventType;
}

export const useEventStore = defineStore("event", () => {
  const customerStore = useCustomerStore();
  const opportunityStore = useOpportunityStore();
  const loading = ref(false);

  const activeReferences = ref<EventFetchParams>({
    projectId: undefined,
    customerId: undefined,
    opportunityId: undefined,
    eventType: EventType.QUALIFICATION_EVENT,
  });

  const events = ref<Event[]>([]);
  const activeEventType = computed(() => activeReferences.value.eventType);
  const unusedEvents = computed(() => {
    switch (activeReferences.value.eventType) {
      case EventType.AFTER_SALES_EVENT:
        return validAfterSalesEvents.value
          .filter((event) => {
            return !events.value.map((e) => e.afterSalesEvent).includes(event);
          })
          .map((event) => {
            return {
              id: event,
              label: getEventTypeLabel(event),
            };
          });
      case EventType.QUALIFICATION_EVENT:
        return validQualificationEvents.value
          .filter((event) => {
            return !events.value
              .map((e) => e.qualificationEvent)
              .includes(event);
          })
          .map((event) => {
            return {
              id: event,
              label: getEventTypeLabel(event),
            };
          });
      case EventType.SALES_EVENT:
        return validSalesEvents.value
          .filter((event) => {
            return !events.value.map((e) => e.salesEvent).includes(event);
          })
          .map((event) => {
            return {
              id: event,
              label: getEventTypeLabel(event),
            };
          });
      default:
        return [];
    }
  });

  function getEventTypeLabel(value: string) {
    switch (activeReferences.value.eventType) {
      case EventType.AFTER_SALES_EVENT:
        return translateEnum("projects.eventAfterSalesEvent", value);
      case EventType.QUALIFICATION_EVENT:
        return translateEnum("customers.eventQualificationEvent", value);
      case EventType.SALES_EVENT:
        return translateEnum("opportunities.eventSalesEvent", value);
      default:
        return "";
    }
  }

  // CRUD Operations

  async function fetch(eventFetchParams: EventFetchParams) {
    loading.value = true;
    activeReferences.value = eventFetchParams;
    events.value = await EventControllerService.getEvents({
      ...eventFetchParams,
    });
    loading.value = false;
  }

  async function create(event: Event) {
    await EventControllerService.createEvent({
      requestBody: event,
    });
    await fetch(activeReferences.value);
  }

  async function remove(eventId: number) {
    await EventControllerService.deleteEvent({ eventId });
    await fetch(activeReferences.value);
  }

  // Helper Functions

  const validAfterSalesEvents = computed(() => {
    return Object.values(AfterSalesEvent).filter(
      (e) => !usedEvents("afterSalesEvent", events.value).includes(e),
    );
  });

  const validQualificationEvents = computed(() => {
    if (!activeReferences.value.customerId) {
      return [];
    }
    const customerStage = customerStore.fetch(activeReferences.value.customerId)
      .value?.pdQualificationStage;
    if (customerStage) {
      const eventsForStage = qualificationEventsPerStage[customerStage];
      return eventsForStage.filter(
        (event) =>
          !usedEvents("qualificationEvent", events.value).includes(event),
      );
    } else {
      return Object.values(QualificationEvent).filter(
        (event) =>
          !usedEvents("qualificationEvent", events.value).includes(event),
      );
    }
  });

  const validSalesEvents = computed(() => {
    if (!activeReferences.value.opportunityId) {
      return [];
    }
    const opportunity = opportunityStore.fetch(
      activeReferences.value.opportunityId,
    );
    if (!opportunity.value?.stage) {
      return [];
    }
    const eventsForStage = salesEventsPerStage[opportunity.value?.stage];
    return eventsForStage.filter(
      (event) => !usedEvents("salesEvent", events.value).includes(event),
    );
  });

  function usedEvents(
    type: "afterSalesEvent" | "qualificationEvent" | "salesEvent",
    eventList: Event[],
  ) {
    if (!eventList) {
      return [];
    }
    return eventList
      .filter((event) => event[type] !== undefined)
      .map((event) => event[type]);
  }

  return {
    loading,
    events,
    unusedEvents,
    activeEventType,
    getEventTypeLabel,
    fetch,
    create,
    remove,
  };
});
