<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { formStyles } from "@/app/base/form/formStyles";
import { useEnumOptions } from "@/app/base/utils/i18n";
import { type Area } from "@/services/backend/models/Area";
import { AreaStatus } from "@/services/backend/models/AreaStatus";
import { type ProjectView } from "@/services/backend/models/ProjectView";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  projects: ProjectView[];
  area: Area;
  onUpdateAreaStatus: (status: AreaStatus | undefined) => Promise<void>;
  onLinkProject: (project: ProjectView | undefined) => Promise<void>;
}>();

const selectedProject = computed<ProjectView | undefined>({
  get: () =>
    props.projects.find((p) => p.id === props.area.linkedPisysObjectId),
  set: async (project: ProjectView | undefined) => {
    await props.onLinkProject(project);
  },
});

const areaStatus = ref<AreaStatus | undefined>(props.area.areaStatus);

watch(areaStatus, async () => {
  await props.onUpdateAreaStatus(areaStatus.value);
});

const linkableProjects = computed(() => {
  return props.projects.filter(
    (project) =>
      project.areaCount === 0 || props.area.linkedPisysObjectId === project.id,
  );
});

const areaStateItems = useEnumOptions("area.state", AreaStatus);
</script>
<template>
  <form class="py-1" @submit.prevent>
    <div class="grid">
      <VSelect
        v-bind="formStyles"
        v-model="areaStatus"
        :label="t('area.stateLabel')"
        :items="areaStateItems"
        clearable
      />
      <VSelect
        v-model="selectedProject"
        class="pt-1"
        :label="t('area.linkedProject')"
        :items="linkableProjects"
        :no-data-text="t('area.noLinkableProject')"
        returnObject
        v-bind="formStyles"
        itemValue="id"
        itemTitle="name"
        clearable
      />
    </div>
  </form>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-rows: auto-flow;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 1ex 1ex;
  padding-top: 1ex;
  padding-bottom: 1ex;
  flex: 1;
}

:deep(.mdi-close-circle) {
  transform: translateY(2px);
}

.v-select {
  --v-input-padding-top: 6px;
  --v-input-padding-bottom: 6px;

  --v-input-control-height: 34px;
  width: 300px;
}
</style>
