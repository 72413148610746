<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, isProxy, onMounted, toRaw } from "vue";
import { useI18n } from "vue-i18n";

import AppButton from "@/app/base/button/AppButton.vue";
import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { type AssignmentType } from "@/app/common/attachments/AssignmentType";
import NoteEditor from "@/app/common/attachments/note/NoteEditor.vue";
import NoteViewer from "@/app/common/attachments/note/NoteViewer.vue";
import { useNoteStore } from "@/app/common/attachments/store/NoteStore";
import { type Mail } from "@/services/backend/models/Mail";
import { type Note } from "@/services/backend/models/Note";

const { t } = useI18n();

const props = defineProps<{
  shown: boolean;
  customerId?: number;
  contactId?: number;
  opportunityId?: number;
  projectId?: number;
  mailAssignmentTypes: AssignmentType[];
  noteAssignmentTypes: AssignmentType[];
}>();

const noteStore = useNoteStore();
const { items: notes, editedItems: editedNotes } = storeToRefs(noteStore);

const loading = computed(() => {
  return noteStore.loading;
});

function lastModifiedChronological(
  a: { type: string; item: Mail | Note },
  b: { type: string; item: Mail | Note },
) {
  if (a.item.lastModificationDate < b.item.lastModificationDate) {
    return -1;
  }
  if (a.item.lastModificationDate > b.item.lastModificationDate) {
    return 1;
  }
  return 0;
}

const combinedItems = computed(() => {
  const result: { type: string; item: Mail | Note }[] = [];
  notes.value.forEach((it) => {
    if (isProxy(it)) {
      result.push({ type: "note", item: toRaw(it) });
    }
  });

  result.sort(lastModifiedChronological);
  result.reverse();
  return result;
});

function addNote() {
  noteStore.createOrUpdatePending({
    id: 0,
    ...props,
    creationDate: "", // Set when NoteStore persists changes
    lastModificationDate: "",
  });
}

onMounted(async () => {
  await noteStore.fetch({ ...props });
});
</script>

<template>
  <LoadingIndicator v-if="loading" />
  <div v-else class="attachments-flex-container">
    <VToolbar class="bg-transparent separator-bottom mb-0">
      <AppButton
        icon="mdi-note-edit-outline"
        highlight
        heavy
        :disabled="!!editedNotes.get(0)"
        :label="t('attachment.addNote')"
        @click="addNote"
      />
    </VToolbar>
    <VContainer class="flex">
      <NoteEditor
        v-if="editedNotes.get(0)"
        :noteId="0"
        :showAssignmentTypes="props.noteAssignmentTypes"
      />
      <div v-for="item in combinedItems" :key="item.item.id">
        <div v-if="item.type === 'note'">
          <NoteEditor
            v-if="editedNotes.get(item.item.id)"
            :noteId="item.item.id"
            :showAssignmentTypes="props.noteAssignmentTypes"
          />
          <NoteViewer v-else :shown="shown" :note="item.item" />
        </div>
      </div>
    </VContainer>
  </div>
</template>

<style scoped>
.attachments-flex-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.flex {
  gap: 1em;
  display: flex;
  flex-direction: column;
}
</style>
