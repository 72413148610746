import { useI18n } from "vue-i18n";

import { NumberFormat } from "@/app/base/utils/i18n";

export function formatCapacity(capacityMw: number): string {
  const { t, n } = useI18n();

  if (capacityMw < 1000) {
    return t("units.capacityMW", {
      capacity: n(capacityMw, NumberFormat.DECIMAL_1),
    });
  } else {
    return t("units.capacityGW", {
      capacity: n(capacityMw / 1000.0, NumberFormat.DECIMAL_2),
    });
  }
}

export function formatArea(areaHa: number): string {
  const { t, n } = useI18n();
  return t("units.areaHectares", { area: n(areaHa, NumberFormat.DECIMAL_1) });
}

export function formatPercent(number: number): string {
  const { t, n } = useI18n();
  return t("units.percent", {
    number: n(number * 100, NumberFormat.DECIMAL_1),
  });
}

export function formatEurosPerYear(number: number): string {
  const { t, n } = useI18n();
  return t("units.eurosPerYear", {
    number: n(number, NumberFormat.INTEGER),
  });
}

interface AggregatedAreaInfo {
  areaCount: number;
  irrBeforeRentAvg: number;
  areaHaDevelopableSum: number;
  totalPowerMwSum: number;
  avgProceedsRentYearSum: number;
  turbineCountSum: number;
}

export function enrichAggregatedAreaInfo(
  entityToEnrich: AggregatedAreaInfo,
  areaInfo: AggregatedAreaInfo,
): void {
  entityToEnrich.areaCount = areaInfo.areaCount;
  entityToEnrich.areaHaDevelopableSum = areaInfo.areaHaDevelopableSum;
  entityToEnrich.avgProceedsRentYearSum = areaInfo.avgProceedsRentYearSum;
  entityToEnrich.irrBeforeRentAvg = areaInfo.irrBeforeRentAvg;
  entityToEnrich.turbineCountSum = areaInfo.turbineCountSum;
  entityToEnrich.totalPowerMwSum = areaInfo.totalPowerMwSum;
}
