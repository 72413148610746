<script setup lang="ts">
import { toRef } from "vue";
import { useRouter } from "vue-router";

import { NumberFormat } from "@/app/base/utils/i18n";
import { formatArea, formatCapacity } from "@/app/common/area/area";
import DataTable from "@/app/common/table/DataTable.vue";
import DataTableContainer from "@/app/common/table/DataTableContainer.vue";
import { tableDef } from "@/app/common/table/DataTableRenderer";
import { type Area } from "@/services/backend/models/Area";
import { type Opportunity } from "@/services/backend/models/Opportunity";
import { useI18n } from "vue-i18n";

const { d, t, n, locale } = useI18n();

interface OpportunityArea {
  opportunity: Opportunity;
  area: Area;
  id: string;
}

const props = defineProps<{
  areas: OpportunityArea[];
  heading: string;
}>();

const router = useRouter();

const table = tableDef<OpportunityArea>(
  (col) => [
    col
      .getter(({ opportunity }) => opportunity.name)
      .label(t("opportunities.opportunity"))
      .searchable(true)
      .comparator((a, b) => a.localeCompare(b, locale.value))
      .grow(),
    col
      .getter(({ area }) => area.name)
      .label(t("entities.area"))
      .searchable(true)
      .comparator((a = "", b = "") => a.localeCompare(b, locale.value))
      .grow(),
    col
      .getter(({ area }) => area.areaHaDevelopable)
      .label(t("entities.areaSize"))
      .stringifier((area) => (area !== undefined ? formatArea(area) : ""))
      .searchable(true)
      .comparator((a = 0, b = 0) => a - b),
    col
      .getter(({ area }) => area.turbineCount)
      .label(t("opportunities.turbineCount"))
      .stringifier((turbineCount) =>
        turbineCount !== undefined ? n(turbineCount, NumberFormat.INTEGER) : "",
      )
      .searchable(true)
      .comparator((a = 0, b = 0) => a - b),
    col
      .getter(({ area }) => area.totalPowerMw)
      .label(t("opportunities.power"))
      .stringifier((power) =>
        power !== undefined ? formatCapacity(power) : "",
      )
      .searchable(true)
      .comparator((a = 0, b = 0) => a - b),
    col
      .getter(({ opportunity }) => opportunity.creationDate)
      .label(t("table.date"))
      .stringifier((cell) => d(cell))
      .comparator((a, b) => Date.parse(a) - Date.parse(b)),
  ],
  "id",
).withData(toRef(props, "areas"), (row) => {
  return router.push(
    router.resolve({
      name: "opportunitySingle",
      params: {
        opportunityId: props.areas.find((e) => e.id === row.id)?.opportunity.id,
      },
    }).href,
  );
});
</script>

<template>
  <DataTableContainer>
    <DataTable
      :heading="heading"
      :entriesText="t('entities.area', 2)"
      :table="table"
    />
  </DataTableContainer>
</template>
