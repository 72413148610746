<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { RouterView, useRouter } from "vue-router";

import DetailPanes from "@/app/base/layout/DetailPanes.vue";
import { AssignmentType } from "@/app/common/attachments/AssignmentType";
import DocumentationPanel from "@/app/common/attachments/DocumentationPanel.vue";
import EventPanel from "@/app/common/attachments/event/EventPanel.vue";
import UrlPanel from "@/app/common/attachments/url/UrlPanel.vue";
import { stringToTab, Tabs } from "@/app/common/tabs/SinglePageTabs";
import { EventType } from "@/services/backend/models/EventType";

import ProjectAreaContainer from "./ProjectAreaContainer.vue";
import ProjectDetailContainer from "./ProjectDetailContainer.vue";

const { t } = useI18n();

defineProps<{ projectId: string }>();

const router = useRouter();

const tabs = [
  { id: Tabs.ATTACHMENTS, name: t("attachment.attachment", 2) },
  { id: Tabs.EVENTS, name: t("attachment.event", 2) },
  { id: Tabs.URLS, name: t("attachment.url", 2) },
];
const activeTab = computed<Tabs | undefined>({
  get: () =>
    stringToTab(router.currentRoute.value.hash.replace(/^#/, "")) ??
    Tabs.ATTACHMENTS,
  set: (newRoute: Tabs | undefined) =>
    newRoute && void router.replace({ hash: "#" + newRoute }),
});
</script>

<template>
  <DetailPanes>
    <template #left>
      <ProjectDetailContainer :projectId="+projectId" />
      <ProjectAreaContainer :projectId="+projectId" />
    </template>

    <VTabs :modelValue="activeTab">
      <VTab
        v-for="(tab, i) in tabs"
        :key="i"
        :value="tab.id"
        @click="activeTab = tab.id"
      >
        {{ tab.name }}
      </VTab>
    </VTabs>

    <DocumentationPanel
      v-show="activeTab === Tabs.ATTACHMENTS"
      :shown="activeTab === Tabs.ATTACHMENTS"
      :mailAssignmentTypes="[AssignmentType.CUSTOMER, AssignmentType.CONTACT]"
      :noteAssignmentTypes="[AssignmentType.CUSTOMER, AssignmentType.CONTACT]"
      :projectId="+projectId"
    />

    <EventPanel
      v-show="activeTab === Tabs.EVENTS"
      :shown="activeTab === Tabs.EVENTS"
      :projectId="+projectId"
      :eventType="EventType.AFTER_SALES_EVENT"
    />

    <UrlPanel v-show="activeTab === Tabs.URLS" :projectId="+projectId" />

    <template #right>
      <RouterView name="right" />
    </template>
  </DetailPanes>
</template>
