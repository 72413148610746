<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { makeEnumComparatorByTranslation } from "@/app/base/utils/EnumComparators";
import { translateEnum } from "@/app/base/utils/i18n";
import { type MaybeRef } from "@/app/base/utils/MaybeRef";
import DataTable from "@/app/common/table/DataTable.vue";
import DataTableContainer from "@/app/common/table/DataTableContainer.vue";
import {
  tableDef,
  type TypedColDef,
} from "@/app/common/table/DataTableRenderer";
import ProjectDeveloperContractReportingCard from "@/app/reporting/projectDeveloperContract/ProjectDeveloperContractReportingCard.vue";
import {
  computeCustomerContractView,
  ContractReportingStatus,
  type CustomerContractView,
} from "@/app/reporting/projectDeveloperContract/ProjectDeveloperContractReportingUtil";
import { type CustomerView } from "@/services/backend/models/CustomerView";

type CustomerContractViewReportingStatusFields = Omit<
  CustomerContractView,
  "id" | "name" | "customerNumber"
>;

const { t, locale } = useI18n();

const router = useRouter();

const loading = ref(true);

const props = defineProps<{
  projectDevelopers: CustomerView[];
}>();

const customerContractViews = ref<CustomerContractView[]>([]);

watchEffect(async () => {
  try {
    customerContractViews.value = await computeCustomerContractView(
      props.projectDevelopers,
    );
    loading.value = false;
  } catch (error) {
    console.error(error);
  }
});

const stringifyContractStatus = (status: ContractReportingStatus) => {
  return translateEnum(
    "reporting.contractReportingList.contractReportingStatus",
    status,
  );
};

const table = tableDef<CustomerContractView>((col) => {
  return [
    col
      .index("name")
      .label(t("customers.customer"))
      .searchable(true)
      .comparator((a, b) => a.localeCompare(b, locale.value))
      .minWidthPx(250)
      .grow(),
    addCommonColumn(
      col,
      "contractStatus",
      t("reporting.contractReportingList.contractStatusLabel"),
    ),
    addCommonColumn(
      col,
      "selfDisclosure",
      t("reporting.contractReportingList.selfDisclosureLabel"),
    ),
    addCommonColumn(
      col,
      "profile",
      t("reporting.contractReportingList.profileLabel"),
    ),
    addCommonColumn(
      col,
      "userAccount",
      t("reporting.contractReportingList.userAccountLabel"),
    ),
    addCommonColumn(
      col,
      "hrData",
      t("reporting.contractReportingList.hrDataLabel"),
    ),
    addCommonColumn(
      col,
      "creditRatingCheck",
      t("reporting.contractReportingList.creditRatingCheckLabel"),
    ),
  ];
}, "id").withData(customerContractViews, (row) => {
  const { href } = router.resolve({
    name: "projectDeveloperSingle",
    params: { customerId: row.id },
  });
  return router.push(href);
});

function addCommonColumn(
  col: TypedColDef<CustomerContractView>,
  index: keyof CustomerContractViewReportingStatusFields,
  label: MaybeRef<string>,
) {
  const reportingStatusComparator = makeEnumComparatorByTranslation(
    "reporting.contractReportingList.contractReportingStatus",
  );

  return col
    .index(index)
    .label(label)
    .stringifier((status) => stringifyContractStatus(status))
    .comparator(reportingStatusComparator)
    .searchable(true);
}

const sentContractCount = computed(() => {
  return customerContractViews.value.filter(
    (contract) => contract.contractStatus === ContractReportingStatus.SENT,
  ).length;
});

const archivedContractCount = computed(() => {
  return customerContractViews.value.filter(
    (contract) => contract.contractStatus === ContractReportingStatus.ARCHIVED,
  ).length;
});
</script>

<template>
  <div class="root-container">
    <a class="title">{{ t("reporting.pdContractReports") }}</a>
    <div class="container">
      <div class="component-above">
        <ProjectDeveloperContractReportingCard
          :number="props.projectDevelopers.length"
          :title="t('reporting.contractReportingList.projectDeveloperCount')"
        />
        <ProjectDeveloperContractReportingCard
          :number="sentContractCount"
          :title="t('reporting.contractReportingList.sentContractCount')"
        />
        <ProjectDeveloperContractReportingCard
          :number="archivedContractCount"
          :title="t('reporting.contractReportingList.archivedContractsCount')"
        />
      </div>

      <DataTableContainer class="container" :showBackgroundImage="false">
        <DataTable :table="table" :loading="loading" />
      </DataTableContainer>
    </div>
  </div>
</template>

<style scoped>
.root-container {
  width: 65vw;
}

.component-above {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  gap: 2em;
  justify-content: center;
}

.container {
  grid-row: table;
}

.title {
  display: flex;
  justify-content: center;
  grid-row: title;
  grid-column: span 2;
  text-align: center;
  font-size: 1.5em; /* h2 font size */
  margin: 0 auto;
}
</style>
