<!-- The widget on the project page, managing the link to a customer -->
<script setup lang="ts">
import { computed, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";

import AppButton from "@/app/base/button/AppButton.vue";
import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import SidebarSection from "@/app/base/sidebar/SidebarSection.vue";
import {
  addToEndOfArray,
  addToStartOfArray,
  deleteElementsFromArray,
  findIndex,
  isEmpty,
} from "@/app/base/utils/array";
import { useLinkStore } from "@/app/common/store/LinkStore";
import { BackendService } from "@/services/backend/BackendService";
import { CustomerType } from "@/services/backend/models/CustomerType";
import { type CustomerView } from "@/services/backend/models/CustomerView";
import { CustomerControllerService } from "@/services/backend/services/CustomerControllerService";
import { ProjectControllerService } from "@/services/backend/services/ProjectControllerService";

import CustomerLinkerContainer from "./CustomerLinkerContainer.vue";
import CustomerWidgetContainer from "./CustomerWidgetContainer.vue";

const props = defineProps<{ projectId: string }>();

const { t } = useI18n();

const linkStore = useLinkStore();

BackendService.setReactively(toRef(linkStore, "customerLinks"), () =>
  CustomerControllerService.getCustomers({ projectId: +props.projectId }),
);

const linkedLandOwners = computed(() => {
  return (
    linkStore.customerLinks?.filter((c) => {
      return c.customerType === CustomerType.LAND_OWNER;
    }) ?? []
  );
});

const linkedProjectDevelopers = computed(() => {
  return (
    linkStore.customerLinks?.filter((c) => {
      return c.customerType === CustomerType.PROJECT_DEVELOPER;
    }) ?? []
  );
});

const allCustomers = BackendService.fetchReactively(() =>
  CustomerControllerService.getCustomers({}),
);

const linkableLandOwners = computed(() => {
  return (
    allCustomers.value?.filter((c) => {
      return (
        c.customerType === CustomerType.LAND_OWNER &&
        findIndex(linkStore.customerLinks, c.id) === -1
      );
    }) ?? []
  );
});

const linkableProjectDevelopers = computed(() => {
  return (
    allCustomers.value?.filter((c) => {
      return (
        c.customerType === CustomerType.PROJECT_DEVELOPER &&
        findIndex(linkStore.customerLinks, c.id) === -1
      );
    }) ?? []
  );
});

const linkingLandOwner = ref(false);
const linkingProjectDeveloper = ref(false);

const removeCustomer = async (customer: CustomerView) => {
  await ProjectControllerService.unlinkProjectFromCustomer({
    customerId: customer.id,
    projectId: +props.projectId,
  });

  deleteElementsFromArray(linkStore.customerLinks, customer);
};

const addCustomers = async (newCustomers: CustomerView[]) => {
  await ProjectControllerService.linkProjectWithCustomers({
    projectId: +props.projectId,
    customerIds: newCustomers.map(({ id }) => id),
  });
  addToEndOfArray(linkStore.customerLinks, ...newCustomers);
};

const createCustomer = (newCustomer: CustomerView) => {
  addToStartOfArray(allCustomers.value, newCustomer);
};

const landOwnerLinkButtonDisabled = computed(() => {
  return !isEmpty(linkedLandOwners.value);
});

const projectDeveloperLinkButtonDisabled = computed(() => {
  return !isEmpty(linkedProjectDevelopers.value);
});
</script>

<template>
  <SidebarSection :header="t('customers.associatedLandOwner')">
    <template #actions>
      <AppButton
        :disabled="landOwnerLinkButtonDisabled"
        icon="mdi-plus"
        highlight
        :label="t('ui.link')"
        @click="linkingLandOwner = true"
      />
    </template>

    <CustomerWidgetContainer
      v-if="linkStore.customerLinks"
      :customers="linkedLandOwners"
      :removeButtonLabel="t('customers.removeCustomerFromProject')"
      @remove-customer="removeCustomer"
    />
    <LoadingIndicator v-else :items="t('customers.customer', 2)" />

    <CustomerLinkerContainer
      v-model:open="linkingLandOwner"
      single
      :items="linkableLandOwners"
      :customerType="CustomerType.LAND_OWNER"
      :onLink="addCustomers"
      :onCreate="createCustomer"
    />
  </SidebarSection>

  <SidebarSection :header="t('customers.associatedProjectDeveloper')">
    <template #actions>
      <AppButton
        :disabled="projectDeveloperLinkButtonDisabled"
        icon="mdi-plus"
        highlight
        :label="t('ui.link')"
        @click="linkingProjectDeveloper = true"
      />
    </template>

    <CustomerWidgetContainer
      v-if="linkStore.customerLinks"
      :customers="linkedProjectDevelopers"
      :removeButtonLabel="t('customers.removeCustomerFromProject')"
      @remove-customer="removeCustomer"
    />
    <LoadingIndicator v-else :items="t('customers.customer', 2)" />

    <CustomerLinkerContainer
      v-model:open="linkingProjectDeveloper"
      single
      :items="linkableProjectDevelopers"
      :customerType="CustomerType.PROJECT_DEVELOPER"
      :onLink="addCustomers"
      :onCreate="createCustomer"
    />
  </SidebarSection>
</template>

<style scoped></style>
