<script setup lang="tsx">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import AppButton from "@/app/base/button/AppButton.vue";
import { formStyles } from "@/app/base/form/formStyles";

import { type TableDefinition } from "./DataTableRenderer";

const props = withDefaults(
  defineProps<{
    heading?: string;
    entriesText?: string;
    table: TableDefinition;
    hasBackgroundImage?: boolean;
    loading?: boolean;
    initialSortColumn?: number;
    initialSortReversed?: boolean;
    onAdd?: () => void;
    onSort?: (sortColumn: number | undefined, sortReversed: boolean) => void;
  }>(),
  {
    heading: undefined,
    entriesText: undefined,
    hasBackgroundImage: true,
    loading: false,
    initialSortColumn: undefined,
    initialSortReversed: false,
    onAdd: undefined,
    onSort: undefined,
  },
);

const { t } = useI18n();

const search = ref<string>();

const sortColumn = ref<number | undefined>(props.initialSortColumn);
const sortReversed = ref(props.initialSortReversed);

const canSearch = computed(() => props.table.canSearch());

const filteredTable = props.table.filter(search);

const sortedTable = filteredTable.sorted(sortColumn, sortReversed);

const sortBy = (column: number) => {
  if (!props.table.canCompare(column)) {
    return;
  }

  if (sortColumn.value !== column) {
    sortColumn.value = column;
    sortReversed.value = false;
  } else if (sortReversed.value === false) {
    sortReversed.value = true;
  } else {
    sortColumn.value = undefined;
  }

  if (props.onSort) {
    props.onSort(sortColumn.value, sortReversed.value);
  }
};

const noEntriesText = computed(() => {
  const entries = props.entriesText ?? props.heading ?? t("table.entries");
  if (props.table.rowCount() === 0) {
    return t("table.noResults", { entries });
  }
  if (filteredTable.rowCount() === 0) {
    return t("table.noFilterResults", { entries });
  }
  return null;
});

const cssGridColumnWidth = (colId: number, tableDef: TableDefinition) => {
  const fixedWidthPx = tableDef.fixedWidthPx(colId);
  const minWidthPx = tableDef.minWidthPx(colId);
  const growFactor = tableDef.grow(colId);

  if (fixedWidthPx) {
    return `${fixedWidthPx}px`;
  }
  if (growFactor && minWidthPx) {
    return `minmax(${minWidthPx}px, ${growFactor}fr)`;
  }
  if (growFactor) {
    return `${growFactor}fr`;
  }
  return "max-content";
};

const cssGridTemplateRows = computed(() =>
  Array.from({ length: props.table.columns.length }, (_, i) =>
    cssGridColumnWidth(i, props.table),
  ).join(" "),
);
</script>

<template>
  <div class="data-table">
    <div>
      <h4 v-if="heading" class="text-h5 capitalize pa-2 pb-6">
        {{ heading }}
      </h4>
      <div class="gradient1 search-field-container pa-2">
        <VProgressCircular
          v-if="loading"
          size="24"
          width="3"
          indeterminate
          class="loading-indicator"
        />

        <VTextField
          v-if="canSearch"
          v-model="search"
          class="search-field"
          :label="t('table.search')"
          v-bind="formStyles"
        />
      </div>
    </div>
    <div class="scrollable">
      <div v-if="sortedTable" class="table">
        <header
          v-for="(_, col) of sortedTable.columns"
          :key="col"
          scope="col"
          @click="sortBy(col)"
        >
          {{ table.label(col) }}
          <span :class="{ hidden: sortColumn !== col }">{{
            sortReversed ? "▼" : "▲"
          }}</span>
        </header>
        <tr
          v-for="(_, row) of sortedTable.rowCount()"
          :key="sortedTable.getId(row)"
        >
          <td
            v-for="(_colData, col) of sortedTable.columns"
            :key="col"
            :class="[
              sortedTable.cssClass(col),
              { clickable: sortedTable.canClick(col) },
            ]"
            @click="sortedTable.click?.(row, col)"
          >
            <Component :is="sortedTable.render(col, row)" />
          </td>
        </tr>
        <tr v-if="noEntriesText && !loading">
          <td
            class="centered-message"
            :style="{ '--col-span': sortedTable.columns.length }"
          >
            {{ noEntriesText }}
          </td>
        </tr>
      </div>
    </div>
    <VCardActions v-if="onAdd || $slots.addButton" class="d-flex justify-end">
      <slot name="addButton">
        <AppButton
          v-if="onAdd"
          :label="t('ui.add')"
          highlight
          solid
          corners
          heavy
          @click="onAdd"
        />
      </slot>
    </VCardActions>
  </div>
</template>

<style scoped>
.table td.clickable {
  cursor: pointer;
}

.table .centered-message {
  justify-content: center;
  text-align: center;
}

.hidden {
  visibility: hidden;
}

.capitalize::first-letter {
  text-transform: capitalize;
}

.table {
  display: grid;
  grid-template-columns: v-bind(cssGridTemplateRows);
  color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
  overflow-x: auto;
}

.table > header {
  font-size: 0.75rem;
  font-weight: bold;
  height: 48px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.table header,
.table tr > td {
  display: flex;
  align-items: center;
  gap: 1ch;
  padding: 0 8px;
  overflow-x: hidden;
}

.clickable {
  cursor: pointer;
}

.table tr {
  display: contents;
}

.table tr > :first-child {
  grid-column-start: 1;
}

.table tr > td {
  border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
  min-height: 48px;
  font-size: 0.875rem;
  grid-column-end: span var(--col-span, 1);
}

.table tr > td > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Expand shortened cell content on hover */
.table tr > td > div:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

.table tr:hover > td {
  background: rgba(var(--v-border-color), var(--v-hover-opacity));
}

.scrollable {
  max-height: 100%;
  overflow-y: auto;
  display: block;
}

.search-field {
  margin-left: auto;
  max-width: 400px;
}

.search-field-container {
  border: 1px solid rgb(var(--v-theme-caeli8));
  border-radius: 4px;
  display: flex;
}

.data-table {
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.loading-indicator {
  align-self: center;
  margin-left: 8px;
}

/*This class is here due to scoping, it can be used from all data table definitions via the cssClass method*/
.min-width-150 {
  min-width: 150px;
}
.v-card-actions {
  padding-top: 1rem;
  padding-right: 0;
}
</style>
