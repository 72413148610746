<script setup lang="ts">
import { computed, onMounted, reactive } from "vue";
import { useI18n } from "vue-i18n";

import TextDisplay from "@/app/base/form/TextDisplay.vue";
import TextEditor from "@/app/base/form/TextEditor.vue";
import DateField from "@/app/base/form/value/DateField.vue";
import DateTimeField from "@/app/base/form/value/DateTimeField.vue";
import NumberField from "@/app/base/form/value/NumberField.vue";
import TextField from "@/app/base/form/value/TextField.vue";
import TristateField from "@/app/base/form/value/TristateField.vue";
import UrlField from "@/app/base/form/value/UrlField.vue";
import FileUploadField from "@/app/process/field/FileUploadField.vue";
import ObjectField from "@/app/process/field/ObjectField.vue";
import PersonField from "@/app/process/field/PersonField.vue";
import { useFileService } from "@/app/process/service/FileService";
import { withoutTypename } from "@/app/process/utils";
import {
  ActivityOutputType,
  FieldType,
  type FieldValueDto,
  type ProcessOutputDto,
  type ValueDto,
} from "@/gql/types";

const CACHE_EMPTY_STATE: ValueDto = {
  valueBoolean: null,
  valueString: null,
  valueNumber: null,
  valueDate: null,
  valueDateTime: null,
  valueEntityId: null,
  valueJson: null,
};

const props = defineProps<{
  contextKey: string;
  contextName?: string;
  outputType: ActivityOutputType | FieldType;
  processOutputDto?: ProcessOutputDto;
  readonly?: boolean;
  noLabel?: boolean;
}>();

const { t } = useI18n();

const fileService = useFileService();

const valueCache = reactive<ValueDto>(
  props.processOutputDto?.value ?? CACHE_EMPTY_STATE,
);

const emits = defineEmits<{
  (event: "change", value: Partial<ProcessOutputDto>): void;
  (event: "delete"): void;
}>();

onMounted(
  async () =>
    await fileService.fetch(
      props.processOutputDto?.id,
      props.outputType === ActivityOutputType.File
        ? props.processOutputDto?.value?.valueEntityId
        : undefined,
    ),
);

function isValueNullOrUndefined(value: unknown): boolean {
  return value === null || value === undefined;
}

const valueTypeMapping: Record<ActivityOutputType | FieldType, keyof ValueDto> =
  {
    [ActivityOutputType.Boolean]: "valueBoolean",
    [ActivityOutputType.String]: "valueString",
    [ActivityOutputType.Url]: "valueString",
    [ActivityOutputType.Date]: "valueDate",
    [ActivityOutputType.DateTime]: "valueDateTime",
    [ActivityOutputType.Number]: "valueNumber",
    [ActivityOutputType.File]: "valueEntityId",
    [ActivityOutputType.Person]: "valueEntityId",
    [ActivityOutputType.Json]: "valueJson",
    // These are unused but need to be kept here for Typescript
    [FieldType.Address]: "valueJson",
    [FieldType.Tag]: "valueJson",
  };

const isCommentFieldEnabled = computed(() => {
  const valueType = valueTypeMapping[props.outputType];
  return valueType ? !isValueNullOrUndefined(valueCache[valueType]) : false;
});

function updateValue(value: Partial<ValueDto>) {
  Object.assign(valueCache, value);
  emits("change", {
    value: {
      ...withoutTypename(valueCache),
    },
  });
}

function updateComment(value: string | null | undefined) {
  emits("change", {
    comment: value,
  });
}

function deleteOutput() {
  Object.assign(valueCache, CACHE_EMPTY_STATE);
  emits("delete");
}
</script>

<template>
  <div class="d-flex w-100">
    <div class="d-flex flex-column ga-4 w-100">
      <TristateField
        v-if="
          outputType === ActivityOutputType.Boolean ||
          outputType === FieldType.Boolean
        "
        :tristateValue="processOutputDto?.value?.valueBoolean"
        :readonly="props.readonly"
        @update="
          (value: boolean | null | undefined) =>
            updateValue({ valueBoolean: value })
        "
      />
      <TextEditor
        v-if="
          (outputType === ActivityOutputType.String ||
            outputType === FieldType.String) &&
          !props.readonly
        "
        :containerId="props.contextKey"
        :previousContent="processOutputDto?.value?.valueString ?? undefined"
        @saveContent="(value: string) => updateValue({ valueString: value })"
      />
      <TextDisplay
        v-if="
          (outputType === ActivityOutputType.String ||
            outputType === FieldType.String) &&
          props.readonly
        "
        :value="processOutputDto?.value?.valueString ?? undefined"
        :markdown="true"
        @update="(value: string) => updateValue({ valueString: value })"
      />
      <NumberField
        v-if="
          outputType === ActivityOutputType.Number ||
          outputType === FieldType.Number
        "
        :initialValue="processOutputDto?.value?.valueNumber ?? undefined"
        :readonly="props.readonly"
        :noLabel="props.noLabel"
        @update="(value: number) => updateValue({ valueNumber: value })"
      />
      <UrlField
        v-if="
          outputType === ActivityOutputType.Url || outputType === FieldType.Url
        "
        :name="contextName"
        :initialValue="processOutputDto?.value?.valueString ?? undefined"
        :readonly="props.readonly"
        @update="(value: string) => updateValue({ valueString: value })"
      />
      <DateField
        v-if="
          outputType === ActivityOutputType.Date ||
          outputType === FieldType.Date
        "
        :dateValue="processOutputDto?.value?.valueDate ?? undefined"
        :readonly="props.readonly"
        @update="(value: string) => updateValue({ valueDate: value })"
      />
      <DateTimeField
        v-if="outputType === ActivityOutputType.DateTime"
        :dateTimeValue="processOutputDto?.value?.valueDateTime ?? undefined"
        :readonly="props.readonly"
        @update="(value: string) => updateValue({ valueDateTime: value })"
      />
      <FileUploadField
        v-if="
          outputType === ActivityOutputType.File ||
          outputType === FieldType.File
        "
        :initialFileId="processOutputDto?.value?.valueEntityId ?? undefined"
        :outputId="props.processOutputDto?.id ?? ''"
        :readonly="props.readonly"
        hideDeleteButton
        @uploaded="(fileId) => updateValue({ valueEntityId: fileId })"
        @deleted="deleteOutput"
      />
      <PersonField
        v-if="
          outputType === ActivityOutputType.Person ||
          outputType === FieldType.Person
        "
        :initialValue="processOutputDto?.value?.valueEntityId ?? undefined"
        :readonly="props.readonly"
        @update="
          (value: string | FieldValueDto) =>
            updateValue({ valueEntityId: value as string })
        "
      />
      <ObjectField
        v-if="outputType === ActivityOutputType.Json"
        :initialValue="processOutputDto?.value?.valueJson ?? undefined"
        :readonly="props.readonly"
        @update="(value: string) => updateValue({ valueJson: value as string })"
      />

      <TextField
        v-if="isCommentFieldEnabled"
        :label="t('processes.singleView.commentLabel')"
        :initialValue="props.processOutputDto?.comment ?? undefined"
        :readonly="props.readonly"
        @update="(value: string) => updateComment(value)"
      />
    </div>

    <div style="width: 2.75rem; height: 2.75rem">
      <VBtn
        v-if="!props.readonly"
        variant="plain"
        size="small"
        color="red-lighten-1"
        icon="mdi-delete-outline"
        @click="() => deleteOutput()"
      />
    </div>
  </div>
</template>
