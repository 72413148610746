<script setup lang="ts">
import { type ChartData } from "chart.js";
import { useI18n } from "vue-i18n";

import BarChartWrapper from "@/app/reporting/common/BarChartWrapper.vue";

const TOP_BAR_HEIGHT = 152;

const LABEL_WIDTH = 205;

const { t } = useI18n();
defineProps<{
  stageChartData: ChartData;
  probabilityChartData: ChartData;
  companyTypeChartData: ChartData;
  sourceChartData: ChartData;
  openPdListForStage: (index: number) => Promise<void>;
  openPdListForProbability: (index: number) => Promise<void>;
  openPdListForCompanyType: (index: number) => Promise<void>;
  openPdListForSource: (index: number) => Promise<void>;
}>();

const noDataText = t("ui.noData");
</script>

<template>
  <a class="title">{{ t("reporting.pdReports") }}</a>
  <BarChartWrapper
    class="boxes"
    :data="stageChartData"
    :subtitle="t('reporting.pdQualificationStageChartLabel')"
    :noDataText="noDataText"
    :onBarClick="(datasetIndex, index) => openPdListForStage(index)"
    :labelWidth="LABEL_WIDTH"
  />
  <BarChartWrapper
    class="boxes"
    :data="probabilityChartData"
    :subtitle="t('reporting.pdProbabiltyChartLabel')"
    :noDataText="noDataText"
    :onBarClick="(datasetIndex, index) => openPdListForProbability(index)"
    :labelWidth="LABEL_WIDTH"
  />
  <BarChartWrapper
    class="boxes"
    :data="companyTypeChartData"
    :subtitle="t('reporting.pdCompanyTypeChartLabel')"
    :noDataText="noDataText"
    :onBarClick="(datasetIndex, index) => openPdListForCompanyType(index)"
    :labelWidth="LABEL_WIDTH"
  />
  <BarChartWrapper
    class="boxes"
    :data="sourceChartData"
    :subtitle="t('reporting.pdSourceChartLabel')"
    :noDataText="noDataText"
    :onBarClick="(datasetIndex, index) => openPdListForSource(index)"
    :labelWidth="LABEL_WIDTH"
  />
</template>
<style scoped>
.title {
  grid-column: span 2;
  text-align: center;
  font-size: 1.5em; /* h2 font size */
}

.boxes {
  height: calc((90vh - v-bind(TOP_BAR_HEIGHT) * 1px) / 2.3);
  width: calc(var(--total-width) / 2.3);
  padding-bottom: 4em;
}
</style>
