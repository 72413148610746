<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";

import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import {
  addToEndOfArray,
  deleteElementsFromArray,
} from "@/app/base/utils/array";
import { useI18n } from "vue-i18n";
import AreaLinkerPanel from "@/app/common/linker/AreaLinkerPanel.vue";
import { useLinkStore } from "@/app/common/store/LinkStore";
import OpportunityAreaList from "@/app/opportunities/single/OpportunityAreaList.vue";
import { useOpportunityStore } from "@/app/opportunities/store/OpportunityStore";
import ProjectCreator from "@/app/projects/board/ProjectCreator.vue";
import { UserAuthService } from "@/services/auth/UserAuthService";
import { BackendService } from "@/services/backend/BackendService";
import { type ApiError } from "@/services/backend/core/ApiError";
import { AfterSalesStage } from "@/services/backend/models/AfterSalesStage";
import { type Area } from "@/services/backend/models/Area";
import { type AreaStatus } from "@/services/backend/models/AreaStatus";
import { type Project } from "@/services/backend/models/Project";
import { type ProjectView } from "@/services/backend/models/ProjectView";
import { OpportunityControllerService } from "@/services/backend/services/OpportunityControllerService";
import { ProjectControllerService } from "@/services/backend/services/ProjectControllerService";

const props = defineProps<{
  opportunityId: number;
  onShowSidebar: (show: boolean) => void;
}>();
const { t } = useI18n();

const badGateway = ref(false);

const opportunityStore = useOpportunityStore();
const opportunity = ref(opportunityStore.fetch(props.opportunityId));

const linkStore = useLinkStore();
const { areaLinks, customerLinks } = storeToRefs(linkStore);

// Linked areas depend on the opportunity. Right side components depend on the area links.
// Make sure to update the links in the store whenever the opportunity changes.
watch(opportunity, () => {
  areaLinks.value = opportunity.value?.areaList ?? [];
});

onMounted(() => {
  if (opportunity.value) {
    areaLinks.value = opportunity.value?.areaList || [];
  }
});

// Linkable areas depend on the land owner linked to this opportunity.
// As this link can change, watch the store and reload if the link changes.
const linkableAreas = ref<Area[]>([]);
watch(
  customerLinks,
  () => {
    if (customerLinks.value && customerLinks.value.length > 0) {
      BackendService.setReactively(
        linkableAreas,
        () =>
          OpportunityControllerService.getLinkableAreas1({
            opportunityId: props.opportunityId,
          }),
        (e: ApiError) => {
          if (e.status === 502) {
            badGateway.value = true;
            return true;
          }
          return false;
        },
      );
    } else {
      linkableAreas.value = [];
    }
  },
  { deep: true },
);

const createProjectDummy: ProjectView = {
  name: t("area.createLinkableProject"),
  id: -1,
  areaCount: 0,
  stage: AfterSalesStage.GENERAL_RESEARCH_AND_PLANNING_LAW,
  creationDate: new Date().toISOString(),
  lastModificationDate: new Date().toISOString(),
  areaHaDevelopableSum: 0,
  avgProceedsRentYearSum: 0,
  irrBeforeRentAvg: 0,
  turbineCountSum: 0,
  totalPowerMwSum: 0,
};

// Linkable projects are projects with the same landowner as this opportunity that have no linked areas yet.const projects = ref<ProjectView[]>([]);
const projects = ref<ProjectView[]>([]);
watch(
  customerLinks,
  async () => {
    if (customerLinks.value && customerLinks.value.length > 0) {
      projects.value = await ProjectControllerService.getProjects({
        customerId: customerLinks.value[0].id,
      });
      projects.value.push(createProjectDummy);
    } else {
      projects.value = [];
    }
  },
  { deep: true },
);

const linkAreaToProject = async (
  project: ProjectView | undefined,
  area: Area,
) => {
  if (project && project.id === createProjectDummy.id) {
    creatingProject.value = true;
    return;
  }
  if (project) {
    await ProjectControllerService.addAreaLink({
      projectId: project.id,
      areaId: area.id,
    });
    // update entities directly in frontend
    area.linkedPisysObjectId = project.id;
    area.linkedPisysObjectName = project.name;
    project.areaCount += 1;
  } else {
    // no project -> remove area from project
    if (area.linkedPisysObjectId) {
      await ProjectControllerService.deleteAreaLinks({
        projectId: area.linkedPisysObjectId,
        areaId: area.id,
      });
    }
    const unlinkedProject = projects.value.find(
      (p) => p.id === area.linkedPisysObjectId,
    );
    if (unlinkedProject && unlinkedProject.areaCount > 0) {
      unlinkedProject.areaCount -= 1;
    }
    area.linkedPisysObjectId = undefined;
    area.linkedPisysObjectName = undefined;
  }
};

const updateAreaStatus = async (areaId: string, areaStatus?: AreaStatus) => {
  await OpportunityControllerService.updateAreaStatus({
    areaId,
    areaStatus,
  });
};

const unlinkArea = async (area: Area) => {
  await OpportunityControllerService.deleteAreaLinks1({
    opportunityId: props.opportunityId,
    requestBody: [area.id],
  });
  deleteElementsFromArray(areaLinks.value, area);
  addToEndOfArray(linkableAreas.value, area);
};

const addAreas = async (areaIds: string[]) => {
  await OpportunityControllerService.addArealinks({
    opportunityId: props.opportunityId,
    requestBody: areaIds,
  });
  const addedAreas =
    linkableAreas.value?.filter((area) => areaIds.includes(area.id)) || [];
  deleteElementsFromArray(linkableAreas.value, ...addedAreas);
  addToEndOfArray(areaLinks.value, ...addedAreas);
};

const addingAreas = ref(false);
const creatingProject = ref(false);

watch(
  () => [addingAreas.value, creatingProject.value],
  () => {
    props.onShowSidebar(!addingAreas.value && !creatingProject.value);
  },
);

const infrawindError = computed<boolean>(() => {
  const unenrichedAreasPresent =
    areaLinks.value?.findIndex((area) => !area.name) !== -1;
  return badGateway.value || unenrichedAreasPresent;
});

async function createProject(project: Project) {
  if (customerLinks.value && customerLinks.value.length > 0) {
    project.customers.push(customerLinks.value[0].id);
  }
  await ProjectControllerService.createProject({
    requestBody: project,
  });
}

const cancelCreatingProject = () => {
  creatingProject.value = false;
};
</script>

<template>
  <div class="flex-container">
    <OpportunityAreaList
      v-if="opportunity"
      :areas="areaLinks ?? []"
      :projects="projects"
      :onUpdateAreaStatus="updateAreaStatus"
      :onLinkAreaToProject="linkAreaToProject"
      :onUnlinkArea="unlinkArea"
      :openAreaAdder="() => (addingAreas = true)"
      :openProjectCreator="() => (creatingProject = true)"
      :infrawindError="infrawindError"
    />
    <LoadingIndicator v-else />

    <AreaLinkerPanel
      v-model:open="addingAreas"
      :areas="linkableAreas ?? []"
      :onLink="addAreas"
    />

    <ProjectCreator
      :open="creatingProject"
      :onAdd="(project) => createProject(project)"
      :userMail="UserAuthService.getUserMail()"
      location="right"
      @update:open="!$event && cancelCreatingProject()"
    />
  </div>
</template>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}
</style>
