<script setup lang="ts">
import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { useI18n } from "vue-i18n";

import OpportunityBoardContainer from "./OpportunityBoardContainer.vue";

const { t } = useI18n();
</script>

<template>
  <Suspense>
    <template #default>
      <OpportunityBoardContainer />
    </template>
    <template #fallback>
      <LoadingIndicator :items="t('opportunities.opportunity', 2)" />
    </template>
  </Suspense>
</template>
