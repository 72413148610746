<script setup lang="tsx">
import { toRef } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { NumberFormat } from "@/app/base/utils/i18n";
import {
  formatArea,
  formatCapacity,
  formatEurosPerYear,
  formatPercent,
} from "@/app/common/area/area";
import DataTable from "@/app/common/table/DataTable.vue";
import DataTableContainer from "@/app/common/table/DataTableContainer.vue";
import { tableDef } from "@/app/common/table/DataTableRenderer";
import AreaActionButtons from "@/app/opportunities/single/AreaActionButtons.vue";
import { type Area } from "@/services/backend/models/Area";
import { type AreaStatus } from "@/services/backend/models/AreaStatus";
import { type ProjectView } from "@/services/backend/models/ProjectView";

import AreaAssignmentForm from "./AreaAssignmentForm.vue";
import { useI18n } from "vue-i18n";

const { t, n, locale } = useI18n();

const props = defineProps<{
  areas: Area[];
  projects: ProjectView[];
  onUpdateAreaStatus: (
    areaId: string,
    areaStatus?: AreaStatus,
  ) => Promise<void>;
  onLinkAreaToProject: (
    project: ProjectView | undefined,
    area: Area,
  ) => Promise<void>;
  onUnlinkArea: (area: Area) => Promise<void> | void;
  openAreaAdder: () => void;
  infrawindError: boolean;
}>();

const table = tableDef<Area>(
  (col) => [
    col
      .getter((area) => area.name)
      .label(t("area.name"))
      .searchable(true)
      .cssClass("min-width-150")
      .grow()
      .comparator((a = "", b = "") => a.localeCompare(b, locale.value)),
    col
      .getter((area) => area.areaHaDevelopable)
      .label(t("entities.area"))
      .stringifier((area) => (area !== undefined ? formatArea(area) : ""))
      .searchable(true)
      .comparator((a = 0, b = 0) => a - b),
    col
      .getter((area) => area.avgProceedsRentYear)
      .label(t("area.proceed"))
      .stringifier((i) => (i ? formatEurosPerYear(i) : ""))
      .searchable(true)
      .comparator((a = 0, b = 0) => a - b),
    col
      .getter((area) => area.irrBeforeRent)
      .label(t("area.irr"))
      .stringifier((i) => (i !== undefined ? formatPercent(i) : ""))
      .searchable(true)
      .comparator((a = 0, b = 0) => a - b),
    col
      .getter((area) => area.totalPowerMw)
      .label(t("area.power"))
      .stringifier((power) =>
        power !== undefined ? formatCapacity(power) : "",
      )
      .searchable(true)
      .comparator((a = 0, b = 0) => a - b),
    col
      .getter((area) => area.turbineCount)
      .label(t("area.turbineCount"))
      .stringifier((turbineCount) =>
        turbineCount !== undefined ? n(turbineCount, NumberFormat.INTEGER) : "",
      )
      .searchable(true)
      .comparator((a = 0, b = 0) => a - b),
    col
      .static()
      .renderer((area) =>
        props.projects ? (
          <AreaAssignmentForm
            projects={props.projects}
            onLinkProject={(p: ProjectView | undefined) =>
              props.onLinkAreaToProject(p, area)
            }
            area={area}
            onUpdateAreaStatus={(status?: AreaStatus) =>
              props.onUpdateAreaStatus(area.id, status)
            }
          />
        ) : (
          <LoadingIndicator />
        ),
      )
      .onClick(null),
    col
      .static()
      .renderer((area) => (
        <AreaActionButtons area={area} onUnlinkArea={props.onUnlinkArea} />
      ))
      .onClick(null),
  ],
  "id",
).withData(toRef(props, "areas"));
</script>

<template>
  <DataTableContainer v-if="!infrawindError" :showBackgroundImage="false">
    <DataTable
      class="table"
      :hasBackgroundImage="false"
      :entriesText="t('entities.area', 2)"
      :table="table"
    >
      <template #addButton>
        <AppButton
          :label="t('entities.linkAreas')"
          highlight
          solid
          corners
          heavy
          @click="openAreaAdder"
        />
      </template>
    </DataTable>
  </DataTableContainer>
  <span v-else class="span">
    {{ t("ui.infrawindError") }}
  </span>
</template>

<style scoped>
.span {
  margin: 24px auto 24px;
  padding: 16px 80px;
  background-color: rgb(var(--v-theme-warning_bg));
  width: fit-content;
  font-size: 0.875rem;
}

.table {
  padding: 16px 0px 0px 0px !important;
  display: flex;
  flex-direction: column;
}

.table > :deep(td) {
  cursor: default !important;
}

:deep(.data-table-wrapper) {
  height: 100% !important;
}
</style>
