import { type Ref } from "vue";

/**
 * Either a value or a reference.
 *
 * If this is used as a part of a class/data structure, there is then no need to
 * wrap the whole component in a `computed` block. This is useful for example
 * with i18n, because the translations have not necessarily been loaded by the
 * time the setup block is executed.
 */
export type MaybeRef<T> = T | Ref<T>;
