<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import AppButton from "@/app/base/button/AppButton.vue";
import { formStyles } from "@/app/base/form/formStyles";
import { useModel } from "@/app/base/utils/useModel";
import KanbanControls from "@/app/common/kanban/KanbanControls.vue";
import { type AfterSalesStage } from "@/services/backend/models/AfterSalesStage";
import { type Project } from "@/services/backend/models/Project";
import { type ProjectView } from "@/services/backend/models/ProjectView";

import ProjectCreator from "./ProjectCreator.vue";
import ProjectKanban from "./ProjectKanban.vue";

const props = defineProps<{
  projects: ProjectView[];
  search: string;
  statusFilter: string[];
  onAdd: (value: Project) => void | Promise<void>;
  userMail: string;
}>();

const emit = defineEmits<{
  (e: "move", project: ProjectView, newStage: AfterSalesStage): void;
  (e: "open", project: ProjectView): void;
  (e: "update:search", value: string): void;
  (e: "update:statusFilter", value: string[]): void;
}>();

const searchModel = useModel(props, "search")(emit);

const { t } = useI18n();

const adding = ref(false);
</script>

<template>
  <KanbanControls>
    <VTextField
      v-model="searchModel"
      v-bind="formStyles"
      :label="t('projects.nameFilter')"
      appendInnerIcon="mdi-magnify"
    />
    <VSpacer />
    <VCardActions>
      <AppButton
        icon="mdi-plus"
        :label="t('ui.add')"
        highlight
        :disabled="adding"
        @click="adding = true"
      />
    </VCardActions>
  </KanbanControls>
  <ProjectKanban
    :projects="projects"
    @open="(project) => emit('open', project)"
    @move="(project, newStage) => emit('move', project, newStage)"
  />
  <ProjectCreator v-model:open="adding" :onAdd="onAdd" :userMail="userMail" />
</template>

<style scoped>
.kanban-controls > label {
  display: contents;
}

.kanban-controls > .v-text-field {
  max-width: 40em;
  margin-right: 1em;
}
</style>
