<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import ButtonGroup from "@/app/base/button/ButtonGroup.vue";
import { useDialog } from "@/app/base/dialog/useDialog";
import { formStyles } from "@/app/base/form/formStyles";
import { useValidators } from "@/app/base/form/validators";
import SidebarHeader from "@/app/base/sidebar/SidebarHeader.vue";
import { deepClone } from "@/app/base/utils/deepClone";
import {DateFormat, useEnumOptions } from "@/app/base/utils/i18n";
import { copyPrefixedIdToClipboard } from "@/app/base/utils/IdCopy";
import { type Opportunity } from "@/services/backend/models/Opportunity";
import { SalesStage } from "@/services/backend/models/SalesStage";
import { type User } from "@/services/backend/models/User";
import { useI18n } from "vue-i18n";

const { t, d } = useI18n();

const { confirm } = useDialog();

const { required } = useValidators();

const props = defineProps<{
  opportunity: Opportunity;
  hideHeader?: boolean;
  button?: string;
  onSave: (value: Opportunity) => Promise<void> | void;
  onRemove?: (customerId: number) => Promise<void>;
  onClose?: () => void;
  creating: boolean;
  userMail: string;
}>();

const opportunityToEdit = ref<Opportunity>(deepClone(props.opportunity));

watchEffect(() => {
  opportunityToEdit.value = deepClone(props.opportunity);
});
const parseDate = (s: string) => d(s, DateFormat.SHORT);

const stages = useEnumOptions("opportunities.stage", SalesStage);

const responsibleEmployee = ref<User | undefined>(undefined);

const formChanged = ref(false);

const confirmDeletion = async (opportunityId: number) => {
  const confirmed = await confirm({
    title: t("opportunities.deleteWarningTitle"),
    message: t("ui.deleteWarningMessage"),
    destructive: true,
    confirmMessage: t("ui.delete"),
  });
  if (confirmed && props.onRemove) {
    await props.onRemove(opportunityId);
  }
};

const mandatoryAttributesSet = computed(() => {
  if (opportunityToEdit.value) {
    const o = opportunityToEdit.value as Opportunity;
    return o.name && o.stage;
  } else {
    return false;
  }
});
</script>

<template>
  <SidebarHeader
    v-if="!hideHeader"
    :header="t('opportunities.opportunityDetails')"
  >
    <AppButton
      class="copy-id-button"
      highlight
      icon="mdi-email-arrow-right-outline"
      :tooltip="
        t('app.copyIdTooltip', { subject: t('opportunities.copyIdSubject') })
      "
      tooltipLocation="bottom"
      @click="copyPrefixedIdToClipboard('O', opportunity.id)"
    />
  </SidebarHeader>
  <VForm>
    <div class="form-items mt-0">
      <VTextField
        v-model="opportunityToEdit.name"
        v-bind="formStyles"
        :label="t('opportunities.name')"
        :rules="[required]"
        @input="formChanged = true"
      />
      <div v-if="!creating" class="flex-row">
        <VTextField
          :modelValue="parseDate(opportunityToEdit.creationDate)"
          readonly
          v-bind="formStyles"
          :label="t('entities.creationDate')"
          @input="formChanged = true"
        />
        <VTextField
          :modelValue="parseDate(opportunityToEdit.lastModificationDate)"
          readonly
          v-bind="formStyles"
          :label="t('entities.lastModificationDate')"
          @input="formChanged = true"
        />
      </div>
      <VSelect
        v-if="!creating"
        v-model="opportunityToEdit.stage"
        v-bind="formStyles"
        :label="t('ui.stage')"
        :items="stages"
        :rules="[required]"
        @update:modelValue="formChanged = true"
      />
    </div>
    <ButtonGroup>
      <AppButton
        v-if="!creating"
        :disabled="opportunityToEdit.areaList.length > 0"
        :label="t('ui.delete')"
        border
        corners
        heavy
        @click="confirmDeletion(opportunityToEdit.id)"
      />
      <AppButton
        :label="button ?? t('ui.save')"
        :disabled="!formChanged || !mandatoryAttributesSet"
        highlight
        solid
        corners
        heavy
        @click="onSave(opportunityToEdit)"
        @success="(formChanged = false), onClose?.()"
      />
    </ButtonGroup>
  </VForm>
</template>

<style scoped>
.v-form {
  display: flex;
  flex-direction: column;
  flex: 0;
  gap: 1rem;
}

.form-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.button-group {
  gap: 1em;
}
.copy-id-button {
  transform: translateX(2em);
}

.background-warning {
  background-color: rgb(var(--v-theme-warning_bg));
}

.flex-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
</style>
