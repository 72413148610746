import { computed, type Ref, unref, type VNode } from "vue";

import { searchFilter, useFilter } from "@/app/base/search/filter";
import { type MaybeRef } from "@/app/base/utils/MaybeRef";

export type Comparator<Cell, Row> = (
  a: Cell,
  b: Cell,
  aRow: Row,
  bRow: Row,
) => number;
export type CellRenderer<Row, Cell> = (
  cell: Cell,
  row: Row,
) => VNode | undefined;
export type CellStringifier<Row, Cell> = (cell: Cell, row: Row) => string;

// Define symbols for use with the Renderer interface.
// This allows for the subclass TypedRenderer to be used as a builder,
// as the underlying properties are not exposed.

const LABEL = Symbol("label");
const SEARCHABLE = Symbol("searchable");
const GROW = Symbol("grow");
const FIXED_WIDTH_PX = Symbol("fixedWidthPx");
const MIN_WIDTH_PX = Symbol("minWidthPx");
const STRINGIFY = Symbol("stringify");
const RENDER = Symbol("render");
const COMPARE = Symbol("compare");
const CSS_CLASS = Symbol("cssClass");
const CLICK = Symbol("click");

/** A class representing a column in a table.
 *
 * The only type parameter is the type of the row itself,
 * as any handling of the underlying datatype is hidden internally.
 */
export interface Renderer<Row> {
  [LABEL]?: MaybeRef<string>;
  [SEARCHABLE]: boolean;
  [GROW]: number;
  [FIXED_WIDTH_PX]: number;
  [MIN_WIDTH_PX]?: number;

  [STRINGIFY](row: Row): string;

  [RENDER](row: Row): VNode | undefined;

  [COMPARE]?: (x: Row, y: Row) => number;
  [CSS_CLASS]?: string;
  [CLICK]?: ((row: Row) => void) | null;
}

/**
 * Define a column in the table.
 *
 * This is defined using an interface in order that the cell type can be erased.
 * This allows for arrays of Renderer<Row>,
 * rather than TypedRenderer<Row, Cell>,
 * which would require a single fixed type Cell.
 */
export class TypedRenderer<Row, Cell> implements Renderer<Row> {
  private stringifyCell: CellStringifier<Row, Cell> = (cell) => String(cell);
  private renderCell: CellRenderer<Row, Cell> = (cell, row) => (
    <div>{this.stringifyCell(cell, row)}</div>
  );
  [LABEL]?: MaybeRef<string>;
  [SEARCHABLE] = false;
  [GROW] = 0;
  [FIXED_WIDTH_PX] = 0;
  [MIN_WIDTH_PX]?: number;
  [COMPARE]?: (x: Row, y: Row) => number;
  [CSS_CLASS]?: string;
  [CLICK]?: ((row: Row) => void) | null;

  constructor(private readonly getter: (row: Row) => Cell) {}

  [STRINGIFY](row: Row) {
    return this.stringifyCell(this.getter(row), row);
  }

  [RENDER](row: Row) {
    return this.renderCell(this.getter(row), row);
  }

  // Setters

  /**
   * Set the heading for the column
   */
  label(label: MaybeRef<string>): this {
    this[LABEL] = label;
    return this;
  }

  /**
   * This makes the column searchable
   *
   * A search bar is shown if this is provided on one or more columns.
   */
  searchable(searchable = true): this {
    this[SEARCHABLE] = searchable;
    return this;
  }

  /**
   * Specify the dynamic width factor (fr) of this column
   *
   * If this is not specified, the column will have a fixed width equal to the
   * maximum width of its content. If two columns are defined with .grow(1) and
   * .grow(2), these will take up 1/3 and 2/3 of the remaining space after all
   * fixed-width columns are rendered.
   */
  grow(grow = 1): this {
    this[GROW] += grow;
    return this;
  }

  /**
   * Specify a fixed width in px
   *
   * If set, this will override the grow factor
   */
  fixedWidthPx(fixedWidthPx: number): this {
    this[FIXED_WIDTH_PX] = fixedWidthPx;
    return this;
  }

  /**
   * Specify a minimum width in px.
   * This is used for growing columns that would otherwise shrink to zero.
   */
  minWidthPx(minWidthPx: number): this {
    this[MIN_WIDTH_PX] = minWidthPx;
    return this;
  }

  /**
   * Define a converter from this value to a string
   *
   * This value is used for searching, and is rendered in the table if no
   * other renderer is provided
   */
  stringifier(stringifier: CellStringifier<Row, Cell>): this {
    this.stringifyCell = stringifier;
    return this;
  }

  /**
   * Define a renderer for this cell
   *
   * This can produce JSX, allowing for dynamic content
   * Make sure to change your script tag language from lang="ts" to lang="tsx"
   */
  renderer(renderer: CellRenderer<Row, Cell>): this {
    this.renderCell = renderer;
    return this;
  }

  /**
   * Define a sorting function for this column
   *
   * If this is provided, sorting is enabled by clicking on the column.
   *
   * Suggested comparators are:
   *
   * - For strings:
   *
   *       .comparator((a, b) => a.localeCompare(b, locale.value)),
   *
   * - For dates:
   *
   *       .comparator((a, b) => +a - +b),
   */
  comparator(comparator: Comparator<Cell, Row>): this {
    this[COMPARE] = (aRow, bRow) =>
      comparator(this.getter(aRow), this.getter(bRow), aRow, bRow);
    return this;
  }

  /** Define a CSS class for the contents of this cell */
  cssClass(cssClass: string): this {
    this[CSS_CLASS] = cssClass;
    return this;
  }

  /**
   * Define a click handler for this cell
   *
   * Set this to null to prevent the row’s click handler being called when
   * clicking on the cell.
   */
  onClick(handler: ((row: Row) => void) | null): this {
    this[CLICK] = handler;
    return this;
  }
}

export interface TypedColDef<Row> {
  static(): TypedRenderer<Row, Row>;

  index<Key extends keyof Row>(key: Key): TypedRenderer<Row, Row[Key]>;

  getter<Cell>(getter: (row: Row) => Cell): TypedRenderer<Row, Cell>;
}

const colDef = <Row,>(): TypedColDef<Row> => ({
  static() {
    return new TypedRenderer<Row, Row>((row) => row);
  },
  index(key) {
    return new TypedRenderer((row) => row[key]);
  },
  getter(getter) {
    return new TypedRenderer(getter);
  },
});

export interface TableDefinition {
  readonly columns: readonly unknown[];

  label(col: number): string | undefined;

  isSearchable(col: number): boolean;

  canSearch(): boolean;

  grow(col: number): number;

  fixedWidthPx(col: number): number | undefined;

  minWidthPx(col: number): number | undefined;

  render(col: number, row: number): VNode | undefined;

  canCompare(col: number): boolean;

  click(row: number, col: number): void;

  canClick(col: number): boolean;

  cssClass(col: number): string | undefined;

  filter(search: Ref<string | undefined>): TableDefinition;

  sorted(col: Ref<number | undefined>, reverse: Ref<boolean>): TableDefinition;

  getId(row: number): string;

  rowCount(): number;
}

export const tableDef = <T,>(
  columns: (colDef: TypedColDef<T>) => readonly Renderer<T>[],
  idKey: keyof T,
) => {
  const columnDefinitions = columns(colDef());
  return {
    withData: (data: Ref<readonly T[]>, clickHandler?: (row: T) => void) =>
      new TableDefinitionImpl(columnDefinitions, idKey, data, clickHandler),
  };
};

/**
 * Define the table, with headers and data
 *
 * This is defined using an interface in order that the row type can be erased.
 */
class TableDefinitionImpl<T> implements TableDefinition {
  constructor(
    readonly columns: readonly Renderer<T>[],
    private readonly idKey: keyof T,
    private readonly data: Ref<readonly T[]>,
    private readonly clickHandler?: (row: T) => void,
  ) {}

  private clickHandlerForCell(col: number) {
    const cellClickHandler = this.columns[col][CLICK];
    if (cellClickHandler === null) {
      return undefined;
    }
    return cellClickHandler ?? this.clickHandler;
  }

  canClick(col: number): boolean {
    return !!this.clickHandlerForCell(col);
  }

  click(row: number, col: number) {
    this.clickHandlerForCell(col)?.(this.data.value[row]);
  }

  label(col: number): string | undefined {
    return unref(this.columns[col][LABEL]);
  }

  isSearchable(col: number): boolean {
    return this.columns[col][SEARCHABLE];
  }

  grow(col: number): number {
    return this.columns[col][GROW];
  }

  fixedWidthPx(col: number): number | undefined {
    return this.columns[col][FIXED_WIDTH_PX];
  }

  minWidthPx(col: number): number | undefined {
    return this.columns[col][MIN_WIDTH_PX];
  }

  getId(row: number): string {
    return String(this.data.value[row][this.idKey]);
  }

  canSearch(): boolean {
    return this.columns.some((column) => column[SEARCHABLE]);
  }

  render(col: number, row: number): VNode | undefined {
    return this.columns[col][RENDER](this.data.value[row]);
  }

  cssClass(col: number): string | undefined {
    return this.columns[col][CSS_CLASS];
  }

  canCompare(col: number): boolean {
    return !!this.columns[col][COMPARE];
  }

  filter(search: Ref<string | undefined>): TableDefinition {
    const columns = this.columns;
    const filteredData = useFilter(
      this.data,
      searchFilter(search, (data) =>
        columns.map((column) =>
          column[SEARCHABLE] ? column[STRINGIFY](data) : undefined,
        ),
      ),
    );
    return new TableDefinitionImpl(
      columns,
      this.idKey,
      filteredData,
      this.clickHandler,
    );
  }

  sorted(
    col: Ref<number | undefined>,
    reversed: Ref<boolean>,
  ): TableDefinition {
    const sortedData = computed(() => {
      if (col.value === undefined) {
        return this.data.value;
      }
      const column = this.columns[col.value];
      const comparator = column[COMPARE];
      if (!comparator) {
        return this.data.value;
      }
      const sign = reversed.value ? -1 : 1;
      return this.data.value.slice().sort((a, b) => sign * comparator(a, b));
    });
    return new TableDefinitionImpl(
      this.columns,
      this.idKey,
      sortedData,
      this.clickHandler,
    );
  }

  rowCount(): number {
    return this.data.value.length;
  }
}
