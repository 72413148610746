<script setup lang="ts">
import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { useI18n } from "vue-i18n";
import { useSalesStages } from "@/app/opportunities/list/useSalesStages";

import OpportunityListContainer from "./OpportunityListContainer.vue";

const { t } = useI18n();

const props = defineProps<{
  salesStages: string[];
}>();

const salesStages = useSalesStages(props.salesStages, "OpportunityListPage");
</script>

<template>
  <Suspense>
    <OpportunityListContainer :salesStages="salesStages" />

    <template #fallback>
      <LoadingIndicator :items="t('opportunities.opportunity', 2)" />
    </template>
  </Suspense>
</template>
