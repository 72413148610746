<script setup lang="ts">
import { sanitizeUrl } from "@braintree/sanitize-url";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import AppButton from "@/app/base/button/AppButton.vue";
import { useDialog } from "@/app/base/dialog/useDialog";
import { useUrlStore } from "@/app/common/attachments/store/UrlStore";
import { type Url } from "@/services/backend/models/Url";

const { t } = useI18n();
const { confirm } = useDialog();
const urlStore = useUrlStore();
const { editedUrlId } = storeToRefs(urlStore);

const props = defineProps<{
  url: Url;
}>();

const remove = async () => {
  if (
    props.url.id &&
    (await confirm({
      title: t("attachment.deleteWarningTitle"),
      message: t("ui.deleteWarningMessage"),
      destructive: true,
      confirmMessage: t("ui.delete"),
    }))
  ) {
    await urlStore.remove(props.url.id);
  }
};

const sanitizedUrl = computed(() => {
  return sanitizeUrl(props.url.address);
});
</script>

<template>
  <VCard
    :href="sanitizedUrl"
    target="_blank"
    :elevation="0"
    class="container gradient1 border1"
  >
    <VTooltip openDelay="500" activator="parent" location="bottom">{{
      url.address
    }}</VTooltip>

    <div class="text-attachment-title">
      {{ url.name }}
    </div>
    <VSpacer />
    <AppButton
      icon="mdi-pencil"
      :label="t('ui.edit')"
      @clickWithEvent.prevent="editedUrlId = url.id"
    />
    <AppButton
      icon="mdi-delete-outline"
      :label="t('ui.delete')"
      @clickWithEvent.prevent="remove"
    />
  </VCard>
</template>

<style scoped>
.container {
  display: flex;
  padding: 0.5em;
  flex-direction: row;
  align-items: center;
  flex: 1;
  min-width: 100%;
}
</style>
