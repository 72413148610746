<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { NumberFormat, translateEnum } from "@/app/base/utils/i18n";
import { type FunnelStage } from "@/app/reporting/common/FunnelStage";
import ReportingFunnel from "@/app/reporting/common/ReportingFunnel.vue";
import { SalesStage } from "@/services/backend/models/SalesStage";
import { type SalesStageCount } from "@/services/backend/models/SalesStageCount";
import { ReportingControllerService } from "@/services/backend/services/ReportingControllerService";

const { t, n } = useI18n();

const router = useRouter();

let opportunityStageCounts: SalesStageCount[] = [];

const label = (stages: SalesStage[]) => {
  if (stages.length === 1) {
    return translateEnum("opportunities.stage", stages[0]);
  }
  const parts: string[] = [];
  stages.forEach((stage) => {
    parts.push(translateEnum("opportunities.stage", stage), " / ");
  });
  parts.pop();
  return parts.join("");
};

function opportunityStage(stages: SalesStage[], color: string): FunnelStage {
  const count = opportunityStageCounts
    .filter((stageCount) => stages.indexOf(stageCount.stage) > -1)
    .reduce((sum, stagesCount) => sum + stagesCount.count, 0);
  return {
    color,
    label: label(stages),
    value: n(count, NumberFormat.INTEGER),
    onClick() {
      return router.push(
        router.resolve({
          name: "opportunityList",
          params: { salesStages: stages },
        }).href,
      );
    },
  };
}

const opportunitiesBeforeLocationCheck = computed((): FunnelStage[] => [
  opportunityStage([SalesStage.LAND_OWNER_STATED], "funnel1"),
  opportunityStage([SalesStage.INITIAL_MEETING], "funnel2"),
  opportunityStage([SalesStage.SHAPE_FILES], "funnel3"),
]);

const opportunitiesAfterLocationCheck = computed<FunnelStage[]>(() => [
  opportunityStage([SalesStage.LOCATION_ANALYSIS], "funnel1"),
  opportunityStage([SalesStage.VSV_IN_NEGOTIATION], "funnel2"),
  opportunityStage([SalesStage.VSV_COMPLETED], "funnel3"),
  opportunityStage([SalesStage.VSV_DECLINED, SalesStage.ABORT], "error"),
]);

// Vue primitives (computed, ref etc.) must be called before await
opportunityStageCounts =
  await ReportingControllerService.countOpportunitiesByStage();
</script>

<template>
  <a class="title">{{ t("reporting.opportunity", 2) }}</a>
  <ReportingFunnel
    :subtitle="`(${t('reporting.opportunitiesBeforeLocationCheck')})`"
    :stages="opportunitiesBeforeLocationCheck"
  />
  <ReportingFunnel
    :subtitle="`(${t('reporting.opportunitiesAfterLocationCheck')})`"
    :stages="opportunitiesAfterLocationCheck"
  />
</template>

<style scoped>
.title {
  grid-column: span 4;
  text-align: center;
  font-size: 1.5em; /* h2 font size */
}
</style>
