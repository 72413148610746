import { type Ref, ref } from "vue";

import { useDialog } from "@/app/base/dialog/useDialog";
import { useI18n } from "vue-i18n";

export function useSave(
  saver: () => unknown | Promise<unknown>,
  success?: () => void,
  failure?: () => void,
): [() => void, Ref<boolean>] {
  const saving = ref(false);
  const { t } = useI18n();
  const { confirm } = useDialog();

  function save() {
    saving.value = true;
    Promise.resolve(saver()).then(
      () => {
        saving.value = false;
        success?.();
      },
      (e: unknown) => {
        saving.value = false;
        console.error("Error while saving:", e);
        confirm({ message: t("ui.saveError") }).then(
          () => failure?.(),
          () => undefined,
        );
      },
    );
  }
  return [save, saving];
}
