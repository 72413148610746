<script setup lang="ts">
import {
  type Chart as ChartJS,
  type ChartData,
  type ChartEvent,
} from "chart.js";
import { computed, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { translateEnum } from "@/app/base/utils/i18n";
import { type BarChartPlugin } from "@/app/reporting/common/ChartJsPlugins";
import LandOwnerReportingChart from "@/app/reporting/landOwner/LandOwnerReportingChart.vue";
import { BackendService } from "@/services/backend/BackendService";
import { AreaStatus } from "@/services/backend/models/AreaStatus";
import { ReportingControllerService } from "@/services/backend/services/ReportingControllerService";

const router = useRouter();

const landOwnerAreas = BackendService.fetchReactively(() => {
  return ReportingControllerService.countLandOwnerAreasByStatus();
});

const landOwnersWithAreas = computed(() => {
  return landOwnerAreas.value?.filter(
    (value) => value.areaStatusCounts.length > 0,
  );
});

const CAELI_6 = "#3C707B";
const FUNNEL_1 = "#284751";
const FUNNEL_2 = "#518EA2";
const FUNNEL_3 = "#9EC2CE";
const FUNNEL_4 = "#CFE0E7";

enum UndefinedState {
  UNDEFINED = "UNDEFINED",
}

type OptionalAreaStatus = AreaStatus | UndefinedState;

const areaStatiOrder = computed(() => {
  const arr = Object.keys(AreaStatus);
  arr.push(UndefinedState.UNDEFINED);
  return arr;
});

const colors: Readonly<Record<OptionalAreaStatus, string>> = {
  [AreaStatus.INITIAL_ANALYSIS]: CAELI_6,
  [AreaStatus.APPROVAL]: FUNNEL_1,
  [AreaStatus.NOT_REALISABLE]: FUNNEL_2,
  [AreaStatus.RENEGOTIATION]: FUNNEL_3,
  [UndefinedState.UNDEFINED]: FUNNEL_4,
};

const labels = ref<string[]>([]);

const maxLabelWidth = 250;

const truncateLabel = (label: string) => {
  const font = "12pt";
  if (getTextWidth(label, font) <= maxLabelWidth) {
    return label;
  } else {
    const threePointsWidth = getTextWidth("...", font);
    let endIndex = label.length - 1;
    while (
      getTextWidth(label.substring(0, endIndex), font) >
      maxLabelWidth - threePointsWidth
    ) {
      endIndex--;
    }
    return label.substring(0, endIndex).trim() + "...";
  }
};

watch(landOwnersWithAreas, () => {
  if (landOwnersWithAreas.value) {
    labels.value = landOwnersWithAreas.value
      ?.map((lo) => lo.landOwnerName ?? "")
      .map(truncateLabel);
  }
});

const barThickness = 20;

const datasets = ref<
  {
    barThickness: number;
    label: string;
    data: number[];
    backgroundColor: string;
  }[]
>([]);
watch(landOwnersWithAreas, () => {
  if (landOwnersWithAreas.value) {
    datasets.value = [];
    for (const status of areaStatiOrder.value) {
      const counts = landOwnersWithAreas.value
        ?.map((lo) => lo.areaStatusCounts)
        .map((areaCounts) =>
          areaCounts.find((count) => {
            return (
              count.status?.toString() === status ||
              // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
              (!count.status && status === UndefinedState.UNDEFINED)
            );
          }),
        )
        .map((count) => (count ? count.count : 0));
      datasets.value.push({
        label: translateEnum("area.state", status),
        data: counts,
        backgroundColor: colors[status as OptionalAreaStatus],
        barThickness,
      });
    }
  }
});

const chartData = computed(() => {
  return {
    labels: labels.value,
    datasets: datasets.value,
  } as ChartData;
});

const onBarClick = async (_datasetIndex: number, index: number) => {
  if (landOwnersWithAreas.value) {
    await router.push(
      router.resolve({
        name: "areaLandOwnerList",
        params: {
          landOwnerId: landOwnersWithAreas.value[index].landOwnerId,
        },
      }).href,
    );
  }
};

const onLabelClick = async (index: number) => {
  if (landOwnersWithAreas.value) {
    await router.push(
      router.resolve({
        name: "landOwnerSingle",
        params: { customerId: landOwnersWithAreas.value[index].landOwnerId },
      }).href,
    );
  }
};

//chartjs only has clickhandlers for inside the chart area
//axes are outside, so we need custom plugins to react to clicks onto these.
const yAxisLabelClickPlugin: BarChartPlugin = {
  id: "yAxisLabelClickPlugin",
  afterEvent: (
    chart: ChartJS<"bar">,
    event: {
      event: ChartEvent;
      inChartArea: boolean;
    },
  ) => {
    const evt = event.event;
    if (evt.type === "click" && !event.inChartArea && evt.x && evt.y) {
      const labelIndex = chart.scales.y.getValueForPixel(evt.y) ?? -1;
      if (labelIndex >= 0 && labelIndex < chart.scales.y.getTicks().length) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Necessary because of a sonarlint issue
        onLabelClick(labelIndex);
      }
    }
  },
};

// re-use canvas object for better performance
const canvas = document.createElement("canvas");

const getTextWidth = (text: string, font: string) => {
  const context = canvas.getContext("2d");
  if (context) {
    context.font = font;
    return context.measureText(text).width;
  }
  return -1;
};
onUnmounted(() => document.removeChild(canvas));
</script>

<template>
  <LandOwnerReportingChart
    :chartData="chartData"
    :onBarClick="onBarClick"
    :plugins="[yAxisLabelClickPlugin]"
  />
</template>
