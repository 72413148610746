<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";

import AppButton from "@/app/base/button/AppButton.vue";
import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { useUrlStore } from "@/app/common/attachments/store/UrlStore";
import UrlEditor from "@/app/common/attachments/url/UrlEditor.vue";
import UrlViewer from "@/app/common/attachments/url/UrlViewer.vue";

const { t } = useI18n();
const urlStore = useUrlStore();
const { urls, loading, isCreateMode, editedUrlId } = storeToRefs(urlStore);

const props = defineProps<{
  projectId?: number;
  customerId?: number;
  opportunityId?: number;
}>();

onMounted(() => urlStore.fetch({ ...props }));
</script>

<template>
  <LoadingIndicator v-if="loading" />
  <div v-else class="attachments-flex-container">
    <VToolbar class="bg-transparent separator-bottom mb-0">
      <AppButton
        icon="mdi-link-variant-plus"
        highlight
        heavy
        :label="t('attachment.addUrl')"
        @click="isCreateMode = true"
      />
    </VToolbar>
    <VContainer>
      <UrlEditor v-if="isCreateMode" />
      <div v-for="url in urls" :key="url.id">
        <UrlEditor v-if="editedUrlId === url.id" :url="url" />
        <UrlViewer v-else :url="url" />
      </div>
    </VContainer>
  </div>
</template>

<style scoped>
.attachments-flex-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.v-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
</style>
