import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { translateEnum } from "@/app/base/utils/i18n";
import {
  Action,
  usePromptService,
  ValidationCode,
} from "@/app/notification/PromptService";
import DocumentListActions from "@/app/process/list/DocumentListActions.vue";
import { type CellContent, type RowItem } from "@/app/process/list/TableTypes";
import { useActivityService } from "@/app/process/service/ActivityService";
import { useDialogService } from "@/app/process/service/DialogService";
import { useFileService } from "@/app/process/service/FileService";
import { useMessageService } from "@/app/process/service/MessageService";
import { useDocumentStore } from "@/app/process/service/persistence/document/DocumentStore";
import { usePersonService } from "@/app/process/service/PersonService";
import { useProcessService } from "@/app/process/service/ProcessService";
import {
  type DocumentDto,
  type DocumentEto,
  type DocumentGenerateResult,
  EntityType,
  type MessageEto,
  type PersonEto,
  useGetDocumentGenerateQuery,
  ValidationLevel,
  type ValidationResult,
} from "@/gql/types";

export enum GenerationStatusEnum {
  SUCCESS = "success",
  WARN = "warn",
  ERROR = "error",
}

export const useDocumentService = defineStore("DocumentService", () => {
  const documentStore = useDocumentStore();
  const activityService = useActivityService();
  const personService = usePersonService();
  const messageService = useMessageService();
  const promptService = usePromptService();
  const dialogService = useDialogService();
  const router = useRouter();
  const { t } = useI18n();

  const processService = useProcessService();
  const fileService = useFileService();

  const translations = {
    name: t("document.name"),
    description: t("document.description"),
    entityType: t("document.entityType"),
    generate: t("document.generate"),
  };

  function deleteAndGoToList(id: string) {
    documentStore.deleteById(id).then(
      () => {
        promptService.success(id, Action.DELETE);
        void router.push({
          name: "documentList",
        });
      },
      (reason) => promptService.failure(id, Action.DELETE, reason),
    );
  }

  function getEntityItemsForDropdown(entityType: EntityType) {
    let items: { id: string; name: string }[];

    switch (entityType) {
      case EntityType.Activity:
        items = activityService
          .getAllActivitiesWithoutArchived()
          .map((item) => ({
            id: item.id,
            name: item.name,
          }));
        break;
      case EntityType.Person:
        items = personService.getAll().map((item: PersonEto) => ({
          id: item.id,
          name: item.name ?? "",
        }));
        break;
      case EntityType.Message:
        items = messageService.getAll().map((item: MessageEto) => ({
          id: item.id,
          name: item.title,
        }));
        break;
      case EntityType.Process:
      default:
        items = processService.getProcesses();
        break;
    }

    return items.map((item) => ({
      text: item.name, // Use the name for display
      value: item.id, // Use the id as the value
    }));
  }

  function getListRowItems(documents?: DocumentEto[]) {
    const results: RowItem[] = [];
    const allDocuments = documents ?? documentStore.getAll();
    for (const document of allDocuments) {
      const rowItem: RowItem = {
        key: document.id,
        cells: {
          ...createNameCell(document.name),
          ...createTypeCell(document.entityType),
          ...createActionsCell(document.id, document.entityType),
        },
        tags: [],
        events: {
          click: () =>
            router.push({
              name: "documentView",
              params: { documentId: document.id },
            }),
        },
      };

      results.push(rowItem);
    }
    return results;
  }

  function createNameCell(name: string): Record<string, CellContent> {
    return {
      [translations.name]: {
        content: name,
        props: { class: "pointer" },
      },
    };
  }

  function createTypeCell(type: EntityType): Record<string, CellContent> {
    return {
      [translations.entityType]: {
        content: translateEnum("entityTypes", type),
        props: { class: "pointer" },
      },
    };
  }

  function createActionsCell(
    id: string,
    type: EntityType,
  ): Record<string, CellContent> {
    return {
      [""]: {
        component: DocumentListActions,
        props: {
          documentId: id,
          entityType: type,
          class: "justify-end",
        },
      },
    };
  }

  const entityTypeLabel = computed(() => {
    if (!dialogService.dialogEntityType) {
      return "";
    }
    switch (dialogService.dialogEntityType) {
      case EntityType.Activity:
        return t("entityTypes.ACTIVITY");
      case EntityType.Person:
        return t("entityTypes.PERSON");
      case EntityType.Message:
        return t("entityTypes.MESSAGE");
      case EntityType.Process:
      default:
        return t("entityTypes.PROCESS");
    }
  });

  const displayColumns = computed((): string[] => {
    return [translations.name, translations.entityType];
  });

  function createOrUpdate(documentDto: DocumentDto) {
    documentStore.createOrUpdate(documentDto).then(
      () =>
        promptService.success(
          documentDto.id,
          Action.SAVE,
          documentDto.name ?? documentDto.id,
        ),
      (reason) => promptService.failure(documentDto.id, Action.SAVE, reason),
    );
    return documentDto.id;
  }

  function generateDocument(entityId: string): Promise<DocumentGenerateResult> {
    return new Promise((resolve, reject) => {
      if (!dialogService.dialogEntityId) {
        return reject(new Error("No document selected"));
      }
      const document = documentStore.getById(dialogService.dialogEntityId);

      if (!document) {
        return reject(new Error("Document not found"));
      }
      const {
        onResult: onResultGenerate,
        loading: loadingGenerate,
        onError: onResultError,
      } = useGetDocumentGenerateQuery({
        documentId: document.id,
        entityId,
        templateFileId: document.templateFileId,
      });

      onResultGenerate((result) => {
        if (loadingGenerate.value) {
          return;
        }
        if (result.data?.documentGenerate) {
          promptService.success(
            document.id,
            Action.GENERATE,
            document.name ?? document.id,
          );
          resolve(result.data.documentGenerate);
        } else {
          const errorMessage =
            result.error?.message ?? "Error generating document";
          promptService.failure(document.id, Action.GENERATE, errorMessage);
          reject(new Error(errorMessage));
        }
      });

      onResultError((error) => {
        promptService.failure(document.id, Action.GENERATE, error.message);
        reject(error);
      });

      return document.id;
    });
  }

  const validationLabelMapping = {
    [ValidationCode.NOT_FOUND]: t("document.validation.placeholderNotFound"),
    [ValidationCode.INVALID_FORMAT]: t("document.validation.invalidFormat"),
  };

  function formatValidationMessages(validation: ValidationResult[]): string {
    return validation
      .map(
        (val) =>
          validationLabelMapping[ValidationCode.NOT_FOUND] +
          ` ${val.params?.join(", ")}`,
      )
      .join("\n");
  }

  function isValidationResult(
    value: ValidationResult | null | undefined,
  ): value is ValidationResult {
    return value !== null && value !== undefined;
  }

  function determineStatus(
    validationResults: ValidationResult[],
  ): GenerationStatusEnum {
    if (validationResults.some((val) => val.level === ValidationLevel.Error)) {
      return GenerationStatusEnum.ERROR;
    }
    if (validationResults.some((val) => val.level === ValidationLevel.Warn)) {
      return GenerationStatusEnum.WARN;
    }
    return GenerationStatusEnum.SUCCESS;
  }

  const documentGenerationResult = ref<DocumentGenerateResult | null>(null);
  const isGenerating = ref(false);
  const generateStatus = ref<GenerationStatusEnum | null>(null);
  const validationMessages = ref("");

  async function startGeneration(entityId: string) {
    if (!entityId) {
      console.error("No item selected for generation");
      return;
    }

    isGenerating.value = true;
    generateStatus.value = null;
    validationMessages.value = "";

    try {
      documentGenerationResult.value = await generateDocument(entityId);

      const validationResults = (
        documentGenerationResult.value.validation ?? []
      ).filter(isValidationResult);
      validationMessages.value = formatValidationMessages(validationResults);

      generateStatus.value = determineStatus(validationResults);
    } catch (error) {
      generateStatus.value = GenerationStatusEnum.ERROR;
      if (error instanceof Error) {
        validationMessages.value = error.message;
      } else {
        validationMessages.value = "Unknown error occurred";
      }

      console.error("Error generating document:", error);
    } finally {
      isGenerating.value = false;
    }
  }

  async function downloadGeneratedDocument() {
    if (documentGenerationResult.value?.resultFileId) {
      await fileService.download(documentGenerationResult.value.resultFileId);
    }
  }

  const entityItems = computed(() => {
    if (dialogService.dialogEntityType) {
      return getEntityItemsForDropdown(dialogService.dialogEntityType);
    }
    return [];
  });

  function resetGeneration() {
    documentGenerationResult.value = null;
    isGenerating.value = false;
    generateStatus.value = null;
    validationMessages.value = "";
  }

  return {
    documentGenerationResult,
    isGenerating,
    generateStatus,
    validationMessages,
    entityItems,
    entityTypeLabel,
    resetGeneration,
    startGeneration,
    downloadGeneratedDocument,
    isLoading: (id?: string) =>
      id ? documentStore.isLoading(id) : documentStore.isLoadingAll,
    getById: (id: string) => documentStore.getById(id),
    getAll: () => documentStore.getAll(),
    getListRowItems,
    createOrUpdate,
    deleteAndGoToList,
    displayColumns,
    markRefetch: (id: string) => documentStore.markRefetch(id),
  };
});
