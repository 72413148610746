<!-- The widget on the opportunity page, managing the link to a customer -->
<script setup lang="ts">
import { computed, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";

import AppButton from "@/app/base/button/AppButton.vue";
import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import SidebarSection from "@/app/base/sidebar/SidebarSection.vue";
import {
  addToEndOfArray,
  addToStartOfArray,
  deleteElementsFromArray,
  findIndex,
  isEmpty,
} from "@/app/base/utils/array";
import { useLinkStore } from "@/app/common/store/LinkStore";
import { BackendService } from "@/services/backend/BackendService";
import { CustomerType } from "@/services/backend/models/CustomerType";
import { type CustomerView } from "@/services/backend/models/CustomerView";
import { CustomerControllerService } from "@/services/backend/services/CustomerControllerService";
import { OpportunityControllerService } from "@/services/backend/services/OpportunityControllerService";

import CustomerLinkerContainer from "./CustomerLinkerContainer.vue";
import CustomerWidgetContainer from "./CustomerWidgetContainer.vue";

const props = defineProps<{ opportunityId: string }>();

const { t } = useI18n();

const linkStore = useLinkStore();

BackendService.setReactively(toRef(linkStore, "customerLinks"), () =>
  CustomerControllerService.getCustomers({
    opportunityId: +props.opportunityId,
  }),
);

const allCustomers = BackendService.fetchReactively(() =>
  CustomerControllerService.getCustomers({}),
);

const linkableLandOwners = computed(() => {
  return (
    allCustomers.value?.filter((c) => {
      return (
        c.customerType === CustomerType.LAND_OWNER &&
        findIndex(linkStore.customerLinks, c.id) === -1
      );
    }) ?? []
  );
});

const linking = ref(false);

const removeCustomer = async (customer: CustomerView) => {
  await CustomerControllerService.unlinkCustomerFromOpportunity({
    opportunityId: +props.opportunityId,
    customerId: customer.id,
  });

  deleteElementsFromArray(linkStore.customerLinks, customer);
};

const addCustomers = async (newCustomers: CustomerView[]) => {
  await OpportunityControllerService.linkOpportunityWithCustomers({
    opportunityId: +props.opportunityId,
    customerIds: newCustomers.map(({ id }) => id),
  });
  addToEndOfArray(linkStore.customerLinks, ...newCustomers);
};

const createCustomer = (newCustomer: CustomerView) => {
  addToStartOfArray(allCustomers.value, newCustomer);
};

const linkButtonDisabled = computed(() => {
  return !isEmpty(linkStore.customerLinks);
});
</script>

<template>
  <SidebarSection :header="t('customers.associatedLandOwner')">
    <template #actions>
      <AppButton
        :disabled="linkButtonDisabled"
        icon="mdi-plus"
        highlight
        :label="t('ui.link')"
        @click="linking = true"
      />
    </template>

    <CustomerWidgetContainer
      v-if="linkStore.customerLinks"
      :customers="linkStore.customerLinks"
      :removeButtonLabel="t('customers.removeCustomerFromOpportunity')"
      @remove-customer="removeCustomer"
    />
    <LoadingIndicator v-else :items="t('customers.customer', 2)" />

    <CustomerLinkerContainer
      v-model:open="linking"
      single
      :items="linkableLandOwners"
      :customerType="CustomerType.LAND_OWNER"
      :onLink="addCustomers"
      :onCreate="createCustomer"
    />
  </SidebarSection>
</template>

<style scoped></style>
