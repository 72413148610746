<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import AppDialog from "@/app/base/dialog/AppDialog.vue";
import TextDisplay from "@/app/base/form/TextDisplay.vue";
import { translateEnum } from "@/app/base/utils/i18n";
import DocumentFileField from "@/app/process/field/DocumentFileField.vue";
import ReadonlyField from "@/app/process/field/ReadonlyField.vue";
import {
  UI_DIALOG,
  useDialogService,
} from "@/app/process/service/DialogService";
import { useDocumentService } from "@/app/process/service/DocumentService";

const props = defineProps<{
  documentId: string;
}>();

const documentService = useDocumentService();
const dialogService = useDialogService();
const router = useRouter();
const { t } = useI18n();
const document = computed(() => documentService.getById(props.documentId));

async function navigateToEditPage() {
  await router.push({
    name: "documentEdit",
    params: { documentId: props.documentId },
  });
}

const isDeleteDialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.DELETE_DOCUMENT),
);
</script>

<template>
  <AppDialog
    v-if="document"
    v-model="isDeleteDialogOpen"
    :title="t('document.deleteDocument')"
    :confirmMessage="t('action.delete')"
    :cancelMessage="t('action.cancel')"
    destructive
    @confirm="
      () => {
        documentService.deleteAndGoToList(props.documentId);
        dialogService.closeDialog(UI_DIALOG.DELETE_DOCUMENT);
      }
    "
    @cancel="dialogService.closeDialog(UI_DIALOG.DELETE_DOCUMENT)"
  >
    <p>
      {{
        t("document.deleteDocumentText", {
          name: document?.name,
        })
      }}
    </p>
  </AppDialog>

  <VCard
    v-if="document"
    class="activity bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-y-auto text-caeli6"
    variant="flat"
  >
    <div class="d-flex align-center justify-space-between">
      <VCardTitle>{{ t("document.document") }}</VCardTitle>
    </div>
    <VCard class="pa-4 d-flex flex-column ga-4">
      <ReadonlyField
        :label="t('document.name')"
        :value="document.name ?? ''"
        data-testid="document-view-name"
      />

      <ReadonlyField
        :label="t('document.entityType')"
        :value="translateEnum('entityTypes', document.entityType)"
        data-testid="document-view-entity-type"
      />

      <TextDisplay
        :label="t('document.description')"
        :value="document?.description ?? ''"
        data-testid="document-view-description"
        markdown
      />

      <ReadonlyField :label="t('document.file')">
        <DocumentFileField
          readonly
          :entityId="document.id"
          :fileId="document.templateFileId"
          data-testid="document-view-template-file"
        />
      </ReadonlyField>
    </VCard>

    <FloatingActionButton
      icon="mdi-pencil"
      first
      primary
      data-testid="document-edit"
      @click="navigateToEditPage"
    />

    <FloatingActionButton
      icon="mdi-trash-can-outline"
      data-testid="document-delete"
      color="error"
      @click="dialogService.openDialog(UI_DIALOG.DELETE_DOCUMENT)"
    />
  </VCard>
</template>
