<script setup lang="ts">
import { computed, ref } from "vue";

import { useI18n } from "vue-i18n";
import { useCustomerStore } from "@/app/customer/store/CustomerStore";
import { type ApiError } from "@/services/backend/core/ApiError";
import { type Area } from "@/services/backend/models/Area";
import { type Opportunity } from "@/services/backend/models/Opportunity";
import { type SalesStage } from "@/services/backend/models/SalesStage";
import { OpportunityControllerService } from "@/services/backend/services/OpportunityControllerService";

import OpportunityAreaList from "./OpportunityAreaList.vue";
import { translateEnum } from "@/app/base/utils/i18n";

const { t } = useI18n();

const props = defineProps<{
  salesStages?: SalesStage[];
  landOwnerId?: number;
}>();

const badGateway = ref(false);

let opportunities: Opportunity[];
let opportunityAreas: { opportunity: Opportunity; area: Area; id: string }[];

try {
  const params = props.salesStages
    ? {
        salesStages: props.salesStages,
      }
    : { landOwnerId: props.landOwnerId };
  opportunities =
    await OpportunityControllerService.getOpportunityAreas(params);

  opportunityAreas = opportunities.flatMap((opportunity) =>
    opportunity.areaList.map((area) => ({
      area,
      opportunity,
      id: `${opportunity.id}:${area.id}`,
    })),
  );
} catch (e) {
  const error = e as ApiError;
  if (error.status === 502) {
    badGateway.value = true;
  } else {
    throw e;
  }
}

const customerStore = useCustomerStore();
const landOwner = props.landOwnerId
  ? customerStore.fetch(props.landOwnerId)
  : undefined;

const heading = computed(() => {
  if (props.salesStages) {
    const parts = [t("entities.area", 2), " - "];
    props.salesStages.forEach((stage) => {
      parts.push(translateEnum("opportunities.stage", stage), ", ");
    });
    parts.pop();
    return parts.join("");
  }
  if (props.landOwnerId) {
    return `${t("entities.area", 2)} - ${landOwner?.value?.name ?? ""}`;
  }
  return "";
});
</script>

<template>
  <OpportunityAreaList
    v-if="!badGateway"
    :areas="opportunityAreas"
    :heading="heading"
  />
  <div v-else style="padding: 10px">
    {{ t("ui.infrawindError") }}
  </div>
</template>
