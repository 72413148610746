<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import AppButton from "@/app/base/button/AppButton.vue";
import SidebarHeader from "@/app/base/sidebar/SidebarHeader.vue";
import {
  formatArea,
  formatCapacity,
  formatEurosPerYear,
  formatPercent,
} from "@/app/common/area/area";
import { type Area } from "@/services/backend/models/Area";

const { t } = useI18n();

const router = useRouter();

const props = withDefaults(
  defineProps<{
    linkedArea: Area | undefined;
    onLink: () => void;
    onRemove: () => Promise<void>;
    allowTransfer?: boolean;
    allowAdding?: boolean;
    onTransfer?: (areaIds: string[]) => void;
    landOwnerLinked: boolean;
    infrawindError: boolean;
  }>(),
  {
    allowAdding: true,
    allowTransfer: false,
    onTransfer: () => {
      /** no-op */
    },
  },
);

const openOpportunity = async () => {
  if (props.linkedArea) {
    const { href } = router.resolve({
      name: "opportunitySingle",
      params: { opportunityId: props.linkedArea.linkedPisysObjectId },
      hash: "#areas",
    });
    await router.push(href);
  }
};

const hasBeenEnrichedByInfraData = computed(
  () =>
    props.linkedArea?.areaHaDevelopable !== undefined ||
    props.linkedArea?.avgProceedsRentYear !== undefined ||
    props.linkedArea?.irrBeforeRent !== undefined ||
    props.linkedArea?.turbineCount !== undefined ||
    props.linkedArea?.totalPowerMw !== undefined,
);
</script>

<template>
  <SidebarHeader :header="t('entities.linkedArea', 1)">
    <AppButton
      v-if="!linkedArea"
      :disabled="!landOwnerLinked || infrawindError || !allowAdding"
      icon="mdi-plus"
      highlight
      :label="t('ui.add')"
      @click="onLink"
    />
    <AppButton
      v-else
      icon="mdi-link-variant-remove"
      highlight
      :label="t('ui.remove')"
      @click="onRemove"
    />
  </SidebarHeader>
  <div v-if="landOwnerLinked && !infrawindError">
    <div v-if="linkedArea" class="px-4 data-table">
      <VTable density="compact">
        <tbody>
          <tr>
            <td>{{ t("area.name") }}:</td>
            <td class="font-weight-bold">{{ linkedArea.name }}</td>
          </tr>
          <tr>
            <td>{{ t("entities.area") }}:</td>
            <td>
              {{
                linkedArea.areaHaDevelopable
                  ? formatArea(linkedArea.areaHaDevelopable)
                  : ""
              }}
            </td>
          </tr>
          <tr>
            <td>{{ t("area.proceed") }}:</td>
            <td>
              {{
                linkedArea.avgProceedsRentYear
                  ? formatEurosPerYear(linkedArea.avgProceedsRentYear)
                  : ""
              }}
            </td>
          </tr>
          <tr>
            <td>{{ t("area.irr") }}:</td>
            <td>
              {{
                linkedArea.irrBeforeRent
                  ? formatPercent(linkedArea.irrBeforeRent)
                  : ""
              }}
            </td>
          </tr>
          <tr>
            <td>{{ t("area.power") }}:</td>
            <td>
              {{
                linkedArea.totalPowerMw
                  ? formatCapacity(linkedArea.totalPowerMw)
                  : ""
              }}
            </td>
          </tr>
          <tr>
            <td>{{ t("area.turbineCount") }}:</td>
            <td>
              {{ linkedArea.turbineCount ? linkedArea.turbineCount : "" }}
            </td>
          </tr>
          <tr>
            <td>{{ t("area.linkedOpportunity") }}:</td>
            <td class="cursor-pointer" @click="openOpportunity">
              {{ linkedArea.linkedPisysObjectName }}
            </td>
          </tr>
        </tbody>
      </VTable>
      <div
        v-if="!hasBeenEnrichedByInfraData"
        class="text-caption background-warning pa-2 ma-2"
      >
        {{ t("entities.areaNotFoundInInfra") }}
      </div>
    </div>
    <p v-else class="text-caption">
      {{ t("entities.noAreaLinked") }}
    </p>
  </div>
  <p v-else-if="!landOwnerLinked" class="text-caption">
    {{ t("entities.mustLinkLandOwner") }}
  </p>
  <p v-else class="text-caption background-warning pa-2">
    {{ t("ui.infrawindError") }}
  </p>
</template>

<style scoped>
.background-warning {
  background-color: rgb(var(--v-theme-warning_bg));
}

.cursor-pointer {
  cursor: pointer;
  text-decoration: underline;
}

.v-table {
  background: none;
}
</style>
