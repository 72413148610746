<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

import AppButton from "@/app/base/button/AppButton.vue";
import ButtonGroup from "@/app/base/button/ButtonGroup.vue";
import { useDialog } from "@/app/base/dialog/useDialog";
import { formStyles } from "@/app/base/form/formStyles";
import { useValidators } from "@/app/base/form/validators";
import SidebarHeader from "@/app/base/sidebar/SidebarHeader.vue";
import { deepClone } from "@/app/base/utils/deepClone";
import { DateFormat, useEnumOptions } from "@/app/base/utils/i18n";
import { copyPrefixedIdToClipboard } from "@/app/base/utils/IdCopy";
import { AfterSalesStage } from "@/services/backend/models/AfterSalesStage";
import { MarketingType } from "@/services/backend/models/MarketingType";
import { type Project } from "@/services/backend/models/Project";

const { t, d } = useI18n();

const { confirm } = useDialog();

const { required } = useValidators();

const props = defineProps<{
  project: Project;
  hideHeader?: boolean;
  button?: string;
  onSave: (value: Project) => void | Promise<void>;
  onRemove?: (customerId: number) => Promise<void>;
  onClose?: () => void;
  creating: boolean;
  userMail: string;
}>();

const projectToEdit = ref<Project>(deepClone(props.project));

watchEffect(() => {
  projectToEdit.value = deepClone(props.project);
});
const parseDate = (s: string) => d(s, DateFormat.SHORT);

const stages = useEnumOptions("projects.stage", AfterSalesStage);

const marketingTypes = useEnumOptions("projects.marketingType", MarketingType);

const formChanged = ref(false);

const confirmDeletion = async (projectId: number) => {
  const confirmed = await confirm({
    title: t("projects.deleteWarningTitle"),
    message: t("ui.deleteWarningMessage"),
    destructive: true,
    confirmMessage: t("ui.delete"),
  });
  if (confirmed && props.onRemove) {
    await props.onRemove(projectId);
  }
};

const mandatoryAttributesSet = computed(() => {
  if (projectToEdit.value) {
    const p = projectToEdit.value as Project;
    return p.name && p.stage;
  } else {
    return false;
  }
});
</script>

<template>
  <SidebarHeader v-if="!hideHeader" :header="t('projects.project')">
    <AppButton
      highlight
      class="copy-id-button"
      icon="mdi-email-arrow-right-outline"
      :tooltip="
        t('app.copyIdTooltip', { subject: t('projects.copyIdSubject') })
      "
      tooltipLocation="bottom"
      @click="copyPrefixedIdToClipboard('P', project.id)"
    />
  </SidebarHeader>

  <VForm>
    <div class="form-items">
      <VTextField
        v-model="projectToEdit.name"
        v-bind="formStyles"
        :label="t('projects.name')"
        :rules="[required]"
        @input="formChanged = true"
      />
      <div v-if="!creating" class="flex-row">
        <VTextField
          :modelValue="parseDate(projectToEdit.creationDate)"
          readonly
          v-bind="formStyles"
          :label="t('entities.creationDate')"
          @input="formChanged = true"
        />
        <VTextField
          :modelValue="parseDate(projectToEdit.lastModificationDate)"
          readonly
          v-bind="formStyles"
          :label="t('entities.lastModificationDate')"
          @input="formChanged = true"
        />
      </div>
      <VSelect
        v-if="!creating"
        v-model="projectToEdit.marketingType"
        v-bind="formStyles"
        :label="t('projects.marketingTypeLabel')"
        :rules="[required]"
        :items="marketingTypes"
        @update:modelValue="formChanged = true"
      />
      <VSelect
        v-if="!creating"
        v-model="projectToEdit.stage"
        v-bind="formStyles"
        :label="t('ui.stage')"
        :rules="[required]"
        :items="stages"
        @update:modelValue="formChanged = true"
      />
    </div>
    <ButtonGroup>
      <AppButton
        v-if="!creating"
        :label="t('ui.delete')"
        border
        corners
        heavy
        @click="confirmDeletion(projectToEdit.id)"
      />
      <AppButton
        :label="button ?? t('ui.save')"
        :disabled="!formChanged || !mandatoryAttributesSet"
        highlight
        solid
        corners
        heavy
        @click="onSave(projectToEdit)"
        @success="(formChanged = false), onClose?.()"
      />
    </ButtonGroup>
  </VForm>
</template>

<style scoped>
.v-form {
  display: flex;
  flex-direction: column;
  flex: 0;
  gap: 1rem;
}

.form-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.button-group {
  gap: 1em;
}
.copy-id-button {
  transform: translateX(2em);
}

.background-warning {
  background-color: rgb(var(--v-theme-warning_bg));
}

.flex-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
</style>
