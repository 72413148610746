<script lang="ts" setup>
import { useStorage } from "@vueuse/core";
import { computed, shallowRef, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { deduplicate } from "@/app/base/utils/array";
import { translateEnum } from "@/app/base/utils/i18n";
import FilteredDataTable from "@/app/process/list/FilteredDataTable.vue";
import { createFieldCells } from "@/app/process/list/FilteredDataTableUtil";
import ProcessActivityActions from "@/app/process/list/ProcessActivityActions.vue";
import ProcessActivityStatusFilter from "@/app/process/list/ProcessActivityStatusFilter.vue";
import { type CellContent, type RowItem } from "@/app/process/list/TableTypes";
import ProcessActivityStatus from "@/app/process/output/ProcessActivityStatus.vue";
import { useActivityService } from "@/app/process/service/ActivityService";
import { useFieldService } from "@/app/process/service/FieldService";
import { useProcessService } from "@/app/process/service/ProcessService";
import { StorageKeys } from "@/config";
import {
  EntityType,
  type ProcessActivityEto,
  ProcessActivityState,
} from "@/gql/types";

const props = defineProps<{
  contextKey?: string;
  processId?: string;
  height?: string;
  grabFocusOnKeydown?: boolean;
  showProcess?: boolean;
  showCustomActivities?: boolean;
  showDetails?: boolean;
}>();

const { t } = useI18n();
const router = useRouter();

const activityService = useActivityService();
const fieldService = useFieldService();
const processService = useProcessService();

const i18n = {
  activityTitle: t("processes.activity"),
  processTitle: t("processes.process"),
  progressTitle: t("processes.progress"),
  templateTitle: t("processes.processTemplate"),
  startActivity: t("processes.startActivity"),
};

const buttonTitle = "";

const displayColumns = computed(() => [
  i18n.activityTitle,
  ...(props.showProcess ? [i18n.processTitle] : []),
  ...deduplicate(
    [
      ...fieldService.getNonTagFieldKeys(EntityType.Activity),
      ...fieldService.getNonTagFieldKeys(EntityType.Process),
    ].map((fieldKey) => fieldKey.name ?? ""),
  ),
  i18n.progressTitle,
  ...(props.showCustomActivities ? [buttonTitle] : []),
]);

const availableTags = computed(() => {
  return fieldService.getTagFieldKeys(EntityType.Activity);
});

const cachedLabels: Record<string, string> = {
  [ProcessActivityState.NotReady]: t("processes.activityState.NOT_READY"),
  [ProcessActivityState.Ready]: t("processes.activityState.READY"),
  [ProcessActivityState.InProgress]: t("processes.activityState.IN_PROGRESS"),
  [ProcessActivityState.PartiallyReady]: t(
    "processes.activityState.PARTIALLY_READY",
  ),
  [ProcessActivityState.Done]: t("processes.activityState.DONE"),
};

const selectedStateFilter = useStorage<ProcessActivityState[]>(
  StorageKeys.process.activityStateFilter.key,
  [],
);

const rowItems = shallowRef<RowItem[]>([]);

const processActivities = computed(() => {
  if (props.processId) {
    if (props.showCustomActivities) {
      return processService.getProcessActivities(props.processId);
    }

    return processService.getProcessActivitiesSortedByGraph(props.processId);
  } else {
    return processService
      .getProcesses()
      .flatMap((process) => processService.getProcessActivities(process.id));
  }
});

watchEffect(() => {
  rowItems.value = createRowItems(processActivities.value);
});

function createRowItems(items: ProcessActivityEto[]): RowItem[] {
  return items.filter(isActivityShown).map((processActivity) => {
    const cells: Record<string, CellContent> = {
      ...createActivityCell(processActivity),
      ...(props.showProcess ? createProcessCell(processActivity) : {}),
      ...createTemplateCell(processActivity),
      ...createFieldCellsWithInheritance(processActivity),
      ...createProgressCell(processActivity),
      ...(props.showCustomActivities ? createActionsCell(processActivity) : {}),
    };

    const tags = fieldService
      .getTagFieldValues(processActivity.activity.id)
      .flatMap(
        (fieldValue) =>
          fieldService.getFieldKey(fieldValue.fieldKeyId)?.name ?? [],
      );

    return {
      key: processActivity.id,
      cells,
      tags,
    };
  });
}

function isActivityShown(processActivity: ProcessActivityEto) {
  if (props.showCustomActivities) {
    return processActivity.activity.custom;
  }

  // This is a workaround. Currently, the full process activity list is filtering out
  // activities with a state of Done or NotReady. Activities with those states can still
  // be in the store after visiting a single process view. Because of that, we need to filter them out here.
  if (
    !props.processId &&
    processActivity.status.state === ProcessActivityState.Done
  ) {
    return false;
  }

  return !(
    selectedStateFilter.value.length > 0 &&
    !selectedStateFilter.value.includes(processActivity.status.state)
  );
}

function createFieldCellsWithInheritance(processActivity: ProcessActivityEto) {
  const startActivityId = processService.getProcess(
    processActivity.processId,
  )?.startActivityId;
  // The last is the most specific one, so it will overwrite the others
  // MUST BE EQUAL to the order in the EntityFieldsCard in ProcessActivitySingleView
  // NICE: refactor, so this logic is shared or - even better - in the backend.
  return {
    ...createFieldCells(startActivityId),
    ...createFieldCells(processActivity.processId),
    ...createFieldCells(processActivity.activity?.id),
    ...createFieldCells(processActivity.id),
  };
}

function createActivityCell(processActivity: ProcessActivityEto) {
  const activity = activityService.getActivity(processActivity.activity.id);
  const isRoot =
    processActivity.status.inputAllCount === 0 &&
    !processActivity.activity.custom;
  return {
    [i18n.activityTitle]: {
      content: isRoot
        ? `${i18n.startActivity}: ${activity?.name ?? ""}`
        : activity?.name ?? "",
      props: {
        class: isRoot ? "pointer text-caeli5" : "pointer",
      },
      events: {
        click: () =>
          router.push({
            name: "processActivity",
            params: {
              processId: processActivity.processId,
              processActivityId: processActivity.id,
            },
          }),
      },
    },
  };
}

function createProcessCell(processActivity: ProcessActivityEto) {
  return {
    [i18n.processTitle]: {
      content: processService.getProcess(processActivity.processId)?.name ?? "",
      props: {
        class: "pointer text-blue-grey-lighten-3",
      },
      events: {
        click: () =>
          router.push({
            name: "process",
            params: { processId: processActivity.processId },
          }),
      },
    },
  };
}

function createTemplateCell(processActivity: ProcessActivityEto) {
  return {
    [i18n.templateTitle]: {
      content:
        activityService.getActivity(
          processService.getProcess(processActivity.processId)
            ?.startActivityId ?? "undefined",
        )?.name ?? "",
    },
  };
}

function createProgressCell(processActivity: ProcessActivityEto) {
  const processActivityState = processActivity.status.state;
  return {
    [i18n.progressTitle]: {
      component: props.showDetails ? ProcessActivityStatus : undefined,
      props: props.showDetails
        ? {
            processActivity,
            cachedLabels,
            showDetails: props.showDetails,
          }
        : undefined,
      content:
        cachedLabels[processActivityState] ??
        translateEnum("processes.activityState", processActivityState),
      sortValue: getProgressSortValue(processActivity),
    },
  };
}

function getProgressSortValue(processActivity: ProcessActivityEto) {
  const usedOutputs = processService.getUsedOutputs(processActivity);
  if (usedOutputs.providedCount === usedOutputs.sum) {
    return 1;
  } else if (usedOutputs.providedCount === 0) {
    return -1;
  } else {
    return 0;
  }
}

function createActionsCell(processActivity: ProcessActivityEto) {
  return {
    [buttonTitle]: {
      component: ProcessActivityActions,
      props: {
        processActivity,
        class: "justify-end",
      },
    },
  };
}
</script>

<template>
  <FilteredDataTable
    :searchByTextColumns="[i18n.activityTitle, i18n.processTitle]"
    :contextKey="props.contextKey ?? props.processId ?? ''"
    :rowItems="rowItems"
    :availableTags="availableTags"
    :exposedColumns="displayColumns"
    :mandatoryColumns="[
      i18n.activityTitle,
      ...(props.showProcess ? [i18n.processTitle] : []),
      ...(props.showCustomActivities ? [buttonTitle] : []),
    ]"
    :initialColumns="[
      i18n.activityTitle,
      ...(props.showProcess ? [i18n.processTitle] : []),
      i18n.progressTitle,
      ...(props.showCustomActivities ? [buttonTitle] : []),
    ]"
    :height="props.height"
    :grabFocusOnKeydown="props.grabFocusOnKeydown"
  >
    <template #header-right>
      <ProcessActivityStatusFilter
        v-if="!props.showCustomActivities"
        v-model="selectedStateFilter"
        :showAllStates="processId !== undefined"
      />
    </template>
  </FilteredDataTable>
</template>

<style scoped>
th {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0.4rem 0.8rem;
  font-size: 0.8rem;
}
</style>
