<script setup lang="ts">
import { type ChartData } from "chart.js";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import BarChartWrapper from "@/app/reporting/common/BarChartWrapper.vue";
import { type BarChartPlugin } from "@/app/reporting/common/ChartJsPlugins";

const { t } = useI18n();

const TOP_BAR_HEIGHT = 152;

const props = defineProps<{
  chartData: ChartData;
  onBarClick: (datasetIndex: number, index: number) => Promise<void>;
  plugins: BarChartPlugin[];
}>();

const noDataText = t("ui.noData");

const chartHeight = computed(() => {
  const numberOfBars = props.chartData.labels?.length ?? 0;
  const barThickness =
    (
      props.chartData.datasets[0] as {
        barThickness: number;
      }
    )?.barThickness || 0;
  return 4 * barThickness + 1.5 * numberOfBars * barThickness;
});
</script>

<template>
  <a class="title">{{ t("reporting.landOwnerReports") }}</a>
  <div class="chartContainer">
    <BarChartWrapper
      class="chartWrapper"
      :showZeroValues="false"
      legend
      stacked
      :data="chartData"
      :noDataText="noDataText"
      :onBarClick="onBarClick"
      :plugins="plugins"
    />
  </div>
</template>
<style scoped>
.chartWrapper {
  height: calc((v-bind(chartHeight) * 1px));
}

.title {
  text-align: center;
  font-size: 1.5em; /* h2 font size */
}

.chartContainer {
  overflow-y: auto;
  width: calc(var(--total-width));
  max-height: calc(90vh - v-bind(TOP_BAR_HEIGHT) * 1px);
}
</style>
